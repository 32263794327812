/* ajustes */
.menu-ajustes{
    min-height: 800px;
    width: 100%;
}
.form-ajustes{
    min-height: 800px;
}


/* listas */
.component-list{
    padding: 10px 10px;
}
.component-list-item{
    padding: 0 0 11px 0 !important;
    border: none !important;
}
.card-list{
    width: 100%;
    border-radius: 10px;
    font-size: 0.8rem;
    margin: 0;
    border: none;
}
.card-list .ant-card-body{
    padding:10px
}

.card-list-btn{
    color: #000000;
}

.btn-nostyle,
.btn-nostyle:hover,
.btn-nostyle:focus{
        color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0);
    /* /* border-color: transparent; */
}
