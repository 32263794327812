 
.landing-header, .bg-white{
    background: #FFF;
}

.landing-header .landing-menu {
    max-width: 1300px;
    text-align: center;
    margin: 0px auto;
    display: block;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after{
    display: none;
}
 
/*Menú landing*/
.landing-menu{
 background: white;
 margin-bottom: 20px;
 margin: 0px auto;
 display: block;
 border: none !important;
}

.landing-menu .ant-menu-item{
    min-width: 64px;
    text-align: center;
}

.landing-menu .itemLogo {
    min-width: 15%;
}
   
/*Globales*/
.text-white{
    color:#FFF !important;
} 

.center{
    text-align: center;
}
.left{
    text-align: left;
}

.mt-5{
    margin-top: 2%;
}

.pd-3{
    padding: 3rem;
}

.mg-10{
    margin:10px !important;
    padding:10px !important;
}


.pb-30{
    padding-bottom:30px !important;
}

.br-20px{
    border-radius: 20px;
}

@media (min-width: 1200px) {
    .xl-align-left{
     text-align: left !important;
     margin-top: 30px;
    } 
}



.login-opc, .itemLogo{
    border: none !important;
}

/*------------------------------HEADER PARA BAUENS PRO-----------------------------------*/
.header-profesional{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:1rem;
    padding-bottom:1rem;
    height:  auto
}

.header-profesional .header-links{
    display: flex
}

.header-profesional .header-extras{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 25%;
}

.header-profesional .header-extras .btn-back{
    border: none;
    color: black;
}
.header-profesional .header-extras .btn-back b{
    color: var(--bauens-blue);
    font-weight: 600;
}

.header-profesional .header-extras svg{
    height: 25px;
}

.header-profesional .header-extras .ant-dropdown-trigger {
   display: flex;
   align-items: center;
}

.header-profesional .header-extras .ant-dropdown-trigger .anticon{
    margin-left: 0.5rem;
}