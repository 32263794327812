  

/*Contacto*/
.div-landing-contacto{
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 115, 225, 0.06);
    border-radius: 5px;
    max-width: 420px;
    margin-bottom: 30px;
}

.div-landing-contacto .col-icon{
    margin-right: 10px;
}
.div-landing-contacto span.ant-typography{
    display: block;
    margin-top: 8px;
}

.div-landing-contacto h5{
    margin: 0px !important;
    padding: 0px !important;
    font-size: 13pt !important;
}

 .lnd-sec-5{
     z-index: 10 !important;
     position: relative;
     margin-bottom: 50px;
 }

.footer-links{
    list-style:none;
    color:#FFF;
    padding-left: 5px !important;
    margin-bottom: 25px;
    text-align: left;
}

.footer-links label{
    color:#b8c5f2 !important;
    font-size: 15pt;
    font-weight: 600;
    line-height: 34px;
color: #000;
}



.icons-redes a,
.footer-links a{
    color:#000;
    font-size: 12pt;
    margin-top: 10px;
}


.footer-links li{
padding-top: 12px;
}

.row-logos-cmp{
    max-width: 720px;
    float: left;
    text-align: left;
    padding: 20px 0px;
}


.row-logos-cmp .ant-col{
    padding: 10px 20px !important;
}

/*Footer*/
.footer{
    font-size: 0.95em;
    line-height: 30px;
    padding: 15px;
    letter-spacing: 1px;
    text-align: center;
    background: #3333CC;
    color: #FFF;
    font-family: Arial, sans-serif;
    font-weight: normal  !important;
    font-style: normal !important;
    font-weight: normal !important;
    
} 

 
@media (min-width: 1200px) {
    
    .bar-logos-cmp{
        display: inline-block;
        position: relative;
        margin-top: -200px;
        margin-bottom: 0px;
    }
    .list-links{
        margin-left: 60px;
    }
}