@import url(https://fonts.gstatic.com);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);
@import url(https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css);
/*------------CSS PARA MODAL LOGIN--------------*/
.modal-bauens .ant-modal-body{
	/* padding-top: 2.5rem; */
	/* padding: 0;  */
	/* margin: 10px; */

	padding: 2.5em 0 2rem 0 ;
}

.modal-bauens .tabs-login .ant-tabs-nav{
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 2.5rem;
}

.modal-bauens .tabs-login .ant-tabs-nav-list{
	width: 100%;
	display: flex;
	justify-content: center;
}

.modal-bauens .tabs-login  .ant-tabs-tab{
	width: 40%;
	display: flex;
	justify-content: center;
	padding: 8px 0;
	color: #262626;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
}

.modal-bauens .tabs-login .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: var(--bauens-blue) !important;
}

.modal-bauens .tabs-login .ant-tabs-ink-bar{
	background-color: var(--bauens-blue) !important;
}

.modal-bauens .checkbox-login{
	font-size: 12px;
	margin: 1rem 0;
}


.modal-bauens .modal-login-form{
	margin: 0 24px 24px 24px
}

.modal-bauens .btn-login,
.modal-bauens .btn-login:hover,
.modal-bauens .btn-login:focus {
	width: 100%;
	background-color: #e0ef4e;
	border: none;
	border-radius: 25px;
	height: 35px;
	color: #272a0d;

}

.modal-bauens .btn-facebook-login,
.modal-bauens .btn-facebook-login:hover,
.modal-bauens .btn-facebook-login:focus {
	background-color: #006afe !important;
	height: 35px !important;
	margin: 1rem 0 !important;
	color: white !important;
	font-weight: 500 !important;
	border: none !important;
	width: 100% !important;
	border-radius: 35px !important;
	position: relative !important;
	cursor: pointer !important;
}

.modal-bauens .btn-google-login,
.modal-bauens .btn-google-login:hover,
.modal-bauens .btn-google-login:focus {
	height: 35px !important;
	font-weight: 500 !important;
	border: solid 1px #a29f9f !important;
	width: 100% !important;
	border-radius: 35px !important;
	position: relative !important;
	color: #a29f9f !important;
	text-align: center !important;
	padding: 0px 0px 0px 0px !important;
	line-height: 15px;
	
}
 

.modal-bauens .btn-google-login span{
	height: 35px !important;
	font-weight: 500 !important;
	width: 100% !important;
	position: relative !important;
	color: #a29f9f !important;
	text-align: center !important;
}


.btn-svg-login-f{
	height: 23px;
	width: 23px;
	position: absolute;
	left: 6px;
	top: 6px;

}

.btn-svg-login-g{
	height: 21px;
	width: 21px;
	position: absolute;
	left: 5px;
	top: 6px;
}

.modal-bauens .ant-form-item-label label,
.modal-bauens .text-label {
	font-weight: bold;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.85);
}

.modal-bauens .modal-title{
	font-weight: 500;
	font-size: 20px;
	color: #000000;
	margin-bottom: 1.5rem;
}

.modal-bauens .ant-form-item-control-input-content{
	display: flex;
	align-items: center;
}

.modal-bauens .text-password{
	margin-left: auto;
	margin-right: 0px;
	font-size: 11px;
	color:  gray;
}

.modal-bauens .text-password:hover{
	text-decoration: underline;
	cursor: pointer;
}

/*-------------------CSS DEL MODAL DE INMUEBLE------------------*/
.modal-inmueble{
	width: 95% !important;
	max-width: 1300px !important;
	top: 10px !important;
}

.modal-inmueble .ant-modal-body{
	padding: 0 0 0 0;
	max-height: 96vh;
	overflow: hidden;
}

.modal-inmueble .col-imagen{
	max-height: 96vh;
	overflow: scroll;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.modal-inmueble .col-imagen::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.modal-inmueble .content-modal-inmueble{
	max-height: 47vh;
	overflow-y: scroll;
	overflow-x: hidden; 
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.modal-inmueble .content-modal-inmueble::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


.modal-inmueble .menu-modal{
	font-size: 12px;
	width: 100%;
}

.modal-inmueble .menu-modal .ant-menu-item{
	margin: 0 15px 0 0;
} 

.modal-inmueble .text-sub{
	font-weight: 600;
} 

.modal-inmueble .text-cat{
	font-weight: 400;
	font-size: smaller;
} 
/*-----------------------------------CSS CARD PASSWORD---------------------------*/
.card-password {
	width: 100%;
	max-width: 450px;
	min-height: 300px;
	border-radius: 12px !important; 
	box-shadow: 0px 5px 10px #ddd;
}

/*---------------------------*/
.modal-contacto{
	width: 80% !important;
	max-width: 1000px !important;
}

.ant-popover-placement-top 
{
	display: none !important;
}

.modal-contacto .contenido-modal{
	padding: 10px 5%;
	width: 100%;
	position: relative;
	min-height: 400px !important;
	max-width: 1500px;
	margin: auto !important;
}


.modal-contacto .btn-closeModal{
	width: 20px;
	height: 20px;
	border: none !important;
}	


.modal-contacto .tituloModal {
	color: #666666 !important;
	text-align: center;
	margin: 0px;
	font-weight: normal;
}

.text-bold{
	font-weight: bold !important;
}

.grupoRadio-mdlCnt{
	width: 100%;
	text-align: center !important;
	position: relative;
	display: table;
}
 
.grupoRadio-mdlCnt .ant-radio-button-wrapper.radioButton{
	float: left;
	height: auto;
	text-align: center;
	border-radius: 10px;
	padding: 10px;
	min-height: 170px !important;
	background: white;


	display: block;
	margin: 10px;
	width: 92%;
	border: solid 2px #C8CAD0 !important
}


.grupoRadio-mdlCnt .radioButton::before{
	color: transparent !important;
	background: transparent !important;
}

.grupoRadio-mdlCnt .ant-radio-button-wrapper .col-img{
	padding-top: 25px;
	height: 100px;
	color: #C8CAD0;
}

.grupoRadio-mdlCnt  .ant-radio-button-wrapper .col-txt{
	padding: 0px 20px;
	height: auto;
}


.grupoRadio-mdlCnt .radioButton .ant-typography{
	font-family: sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	display: inline-block;
	line-height: 20px !important;
	color: #666666;
}



.grupoRadio-mdlCnt  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton,
.grupoRadio-mdlCnt  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton:hover,
.grupoRadio-mdlCnt  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton:focus
{
	background: linear-gradient(90deg, #3333CC 0%, #2424A4 102.04%);
	color: #FFFFFF !important;
	border-radius: 10px;
	border: solid 2px #2424A4 !important;
}


.grupoRadio-mdlCnt .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton .col-img,
.grupoRadio-mdlCnt .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton .col-txt .ant-typography{
	color: white !important;	
}


.pd-30px{
	padding:20px;
}

.txt-gray{
	color: #A4A4A4 !important;
	 
}

.txt-gray.ant-divider-horizontal.ant-divider-with-text::before, 
.txt-gray.ant-divider-horizontal.ant-divider-with-text::after {
	top:0% !important;
	border-top: 1px solid #a4a4a4b6 !important;
}


.row-info {
	font-weight: 500;
}

.row-info .text-blue{
	color: #3333CC !important;
}

.btnArrowCircle{    
	display: block;
    position: relative;
	top: -85px;
    margin-left: 0px !important;
    margin-right: 0px !important;
	padding-left: 0px !important;
}
 

.btnArrowCircleList,
.btnArrowCircle{
	width:  26px;
	height: 26px;
	padding: 0px;
	margin: 0px;
	border-radius: 20px;
	border-color: transparent !important;
}

.btnArrowCircleList{
	width:  20px !important;
	height: 20px !important;
	padding: 0px  !important;
	margin: 0px !important;
}

.list-profesiones,
.wd-100prs {
    width: 100%;
}

.mt-30px{
	margin-top: 30px;
}

.mt-20px{
	margin-top: 20px !important;
}


/*-----------------------CSS Modal contacto---------------------------*/
.modal-contacto .ant-tabs-nav-wrap{
	display: none !important;
	visibility: hidden !important;
}
.frm-contacto label{
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.85);


}
.btn-mdl-guardar{
	color: #FFFFFF !important;
	border-radius: 5px !important;
	padding: 5px !important;
	background: #87D068 !important;
	font-size: 16px !important;
	min-width: 170px !important;
	height: 50px !important;
}


/*Step paquetes*/

.rdbtn-paquete{
	background: #FFFFFF !important;
	float: left;
	height: auto !important;
	/* width: 90% !important;
	margin: 5% !important; */
	text-align: center !important;
	padding: 0px !important;
	border-radius: 0px 0px 10px 10px !important;
	border-color: transparent !important;
	box-shadow: -2px -2px 8px  rgba(3, 68, 158, 0.301);
	border: none !important;
	min-height: 350px !important;
}


.rdbtn-paquete .col-nombre{
	background: #1890FF !important;
	font-family: Montserrat !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 50px !important;

	display: block !important;
	align-items: center !important;
	text-align: center !important;
	letter-spacing: 0.2px !important;
	color: #FFFFFF !important;
	margin-top: -2px !important;
	width: calc(100% + 2px) !important;
}

.rdbtn-paquete .col-precio{
	padding: 60px 5px 10px 5px !important;
}


.rdbtn-paquete .col-precio .ant-typography{
	color: #87D068 !important;
	font-family: Montserrat !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 22px !important;
	line-height: 50px !important;
	
	align-items: center !important;
	text-align: center !important;
	letter-spacing: 0.2px !important;

	display: inline-block !important;
	width: auto !important;
}

 
.rdbtn-paquete .col-precio span.ant-typography{
	font-size: 15px !important;
	margin-left: 5px !important;
}



.rdbtn-paquete .col-descripcion{
	font-family: Montserrat !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 18px !important;
	text-align: center !important;
	letter-spacing: 0.2px !important;
	color: #666666 !important;
	padding: 15px 10% !important;
}


.rdbtn-paquete .col-boton{
	padding: 20px;
}
 
.rdbtn-paquete .col-boton .btn-morado 
{
	background: #492AF9;
	border-radius: 5px;
	border-color:  transparent !important;
	color: #FFFFFF !important
}

.btn-mdl-finalizar,
.btn-mdl-finalizar:hover,
.btn-mdl-finalizar:focus,
.btn-mdl-finalizar:active{
	background: #E0EF4D !important;
	border-color:  #E0EF4D !important;
	border-radius: 5px;
	min-width: 150px !important;
	margin-top: 100px;
	color:#000;
}

.ant-radio-button-wrapper-checked.rdbtn-paquete{
	background: #188fff49  !important;
}

.rdbtn-paquete.ant-radio-button-wrapper:not(:first-child)::before {
	background: transparent !important;
}

.resultado-success .ant-result-title,
.resultado-success .ant-result-subtitle
{
font-family: Montserrat !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 18px !important;
line-height: 25px !important;
text-align: center !important;
color: #A4A4A4 !important;
}

@media (min-width: 900px) {
	.grupoRadio-mdlCnt .radioButton{
		width:46% !important;
		margin: 10px;
	}

	.rdbtn-paquete {
		width:40% !important;
		margin: 10px;
	
	}
}


@media (min-width: 1200px) {
	.rdbtn-paquete ,
	.grupoRadio-mdlCnt .radioButton{
		width: 30% !important;
		margin: 10px 1% !important;
		min-width: 220px;
	}

	.grupoRadio-mdlCnt {
		width: 100% !important;
		text-align: center !important;
		display: inline-block !important;
		text-align: center !important;
		padding-left: 8% !important;
	}
}
 
.avatar-group-card .ant-avatar-string{
    margin-top: 15px;
}

/*para los card de project manager*/
.avatar-info-card .ant-avatar-string{
    margin-top: 5px;
}



/*.ant-input,*/
/*.ant-input-affix-wrapper {*/
/*    width:100%!important;*/
/*    background-color: #E5EDF5!important;*/
/*    box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.07)!important;*/
/*}*/

/*.form-item-simple-input,*/
/*.ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow*/
/*{*/
/*    padding: 5px!important;*/
/*    height: 38px;*/

/*}*/

/*.form-item-select .ant-form-item-control-input-content{*/
/*    margin: -1px 0px 0 -3px!important*/
/*}*/


/*.form-item-select .ant-form-item-control-input {*/
/*    width: calc(100% + 10px)*/
/*}*/

/*.form-item-container-input.ant-input-affix-wrapper{*/
/*    height: 38px!important;*/
/*}*/
/*.ant-select-selector {*/
/*    padding: 3px 10px 0 10px!important;*/
/*    margin: -4px 0 0 -2px!important;*/
/*    height: 38px!important;*/
/*    background: #e5edf5!important;*/
/*    box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.07)!important;;*/
/*}*/


.form-item-container{
    display: inline-block!important;
    width: 50% ;
    padding: 6px !important;
}
/* .ant-form-item {
     margin-bottom: 0px!important;
} */

.form-item-container-input{
    background: #e5edf5!important;
}

.form-item-container-input .ant-input{
    background: #e5edf5 !important;
}

.form-item-simple-input{
    background: #e5edf5 !important;
}

.select-simple .ant-select-selector{
    background: #e5edf5 !important;
}

/*.payform.responsive .form-item-container{*/
/*        display: inline-block!important;*/
/*        width: 100% !important;*/
/*        padding: 6px !important;*/
/*    }*/

.dynamic-delete-button{
    margin: 8px 8px!important;
    position: absolute;
    font-size: 20px;
}



#paypear-submit{
    display: block;
    margin: 3em auto 0 auto;
    background: #017AFF !important;
    width: 260px;
}


.ant-input.ant-input-lg{
    background: #e5edf5;
}


.w-100{
    width: 100%!important;
}

.shipping .ant-radio-group.ant-radio-group-outline.ant-radio-group-large{
    width: 100%;
}

.form-item-shipping{
    padding: 10px 2em 0 2em!important;
}

.shipping-information {
    width: 100%;
    min-height: 50px!important;
    margin: 10px 0 10px 0;
    /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;*/
    padding: 10px 2em 0 2em!important;
    border-radius: .4rem!important;
    color: #13A7FF;
    /*background-color: #E5EDF5;*/
    /*box-shadow:0.4em 0.3rem 0.3rem #E5EDF5 !important*/
    box-shadow: 0.4em 0.4em 1rem #E5EDF5 !important;

}


.shipping-information .cost {
    float: right;
    color: #7E97B8!important;
    /*position: absolute;*/
    /*right: 0px;*/
    /*top: 0px*/
}

.Form {
    -webkit-animation: fade 200ms ease-out;
            animation: fade 200ms ease-out;
}

.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    /*border-style: none;*/
    background-color: #e5edf5;
    will-change: opacity, transform;
    /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),*/
    /*inset 0 1px 0 #829fff;*/
    border-radius: 2px;
    border: 1px solid #d9d9d9;
}

.FormRow {
    display: flex;
    align-items: center;
    margin-left: 15px;
    /*border-top: 1px solid red;*/
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #c4f0ff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@-webkit-keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    /* Hack to hide the default webkit autofill */
    -webkit-transition: background-color 100000000s;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
            animation: 1ms void-animation-out;
}

.FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #fff;
    background-color: red;
    -webkit-animation: 1ms void-animation-out;
            animation: 1ms void-animation-out;
}

.FormRowInput::-webkit-input-placeholder {
    color: #87bbfd;
}

.FormRowInput::placeholder {
    color: #87bbfd;
}

.StripeElement--webkit-autofill {
    background: blue !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.SubmitButton {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #f6a4eb;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
    background-color: #d782d9;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
    -webkit-transform: scale(0.99) translateY(15px);
            transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
}

.ErrorMessage {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
    opacity: 0;
    -webkit-animation: fade 150ms ease-out;
            animation: fade 150ms ease-out;
    -webkit-animation-delay: 50ms;
            animation-delay: 50ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 10px;
}

.Result {
    margin-top: 50px;
    text-align: center;
    -webkit-animation: fade 200ms ease-out;
            animation: fade 200ms ease-out;
}

.ResultTitle {
    color: #fff;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
}

.ResultMessage {
    color: #9cdbff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
}

.ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
}


.Paypear{
    width: 100%;
    /* min-height: 100vh; */
    /*background: ;*/
    /* padding-top: 10%; */
}


#payment-processor{
    margin: 0 auto;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

#paypear-submit{
    display: block;
    margin: 3em auto 0 auto;
    background: #017AFF !important;
    width: 260px;
}

.ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab-active.ant-tabs-tab.ant-tabs-tab-disabled{
    color: black!important;
}


 
.landing-header, .bg-white{
    background: #FFF;
}

.landing-header .landing-menu {
    max-width: 1300px;
    text-align: center;
    margin: 0px auto;
    display: block;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after{
    display: none;
}
 
/*Menú landing*/
.landing-menu{
 background: white;
 margin-bottom: 20px;
 margin: 0px auto;
 display: block;
 border: none !important;
}

.landing-menu .ant-menu-item{
    min-width: 64px;
    text-align: center;
}

.landing-menu .itemLogo {
    min-width: 15%;
}
   
/*Globales*/
.text-white{
    color:#FFF !important;
} 

.center{
    text-align: center;
}
.left{
    text-align: left;
}

.mt-5{
    margin-top: 2%;
}

.pd-3{
    padding: 3rem;
}

.mg-10{
    margin:10px !important;
    padding:10px !important;
}


.pb-30{
    padding-bottom:30px !important;
}

.br-20px{
    border-radius: 20px;
}

@media (min-width: 1200px) {
    .xl-align-left{
     text-align: left !important;
     margin-top: 30px;
    } 
}



.login-opc, .itemLogo{
    border: none !important;
}

/*------------------------------HEADER PARA BAUENS PRO-----------------------------------*/
.header-profesional{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:1rem;
    padding-bottom:1rem;
    height:  auto
}

.header-profesional .header-links{
    display: flex
}

.header-profesional .header-extras{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 25%;
}

.header-profesional .header-extras .btn-back{
    border: none;
    color: black;
}
.header-profesional .header-extras .btn-back b{
    color: var(--bauens-blue);
    font-weight: 600;
}

.header-profesional .header-extras svg{
    height: 25px;
}

.header-profesional .header-extras .ant-dropdown-trigger {
   display: flex;
   align-items: center;
}

.header-profesional .header-extras .ant-dropdown-trigger .anticon{
    margin-left: 0.5rem;
}
  

/*Contacto*/
.div-landing-contacto{
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 115, 225, 0.06);
    border-radius: 5px;
    max-width: 420px;
    margin-bottom: 30px;
}

.div-landing-contacto .col-icon{
    margin-right: 10px;
}
.div-landing-contacto span.ant-typography{
    display: block;
    margin-top: 8px;
}

.div-landing-contacto h5{
    margin: 0px !important;
    padding: 0px !important;
    font-size: 13pt !important;
}

 .lnd-sec-5{
     z-index: 10 !important;
     position: relative;
     margin-bottom: 50px;
 }

.footer-links{
    list-style:none;
    color:#FFF;
    padding-left: 5px !important;
    margin-bottom: 25px;
    text-align: left;
}

.footer-links label{
    color:#b8c5f2 !important;
    font-size: 15pt;
    font-weight: 600;
    line-height: 34px;
color: #000;
}



.icons-redes a,
.footer-links a{
    color:#000;
    font-size: 12pt;
    margin-top: 10px;
}


.footer-links li{
padding-top: 12px;
}

.row-logos-cmp{
    max-width: 720px;
    float: left;
    text-align: left;
    padding: 20px 0px;
}


.row-logos-cmp .ant-col{
    padding: 10px 20px !important;
}

/*Footer*/
.footer{
    font-size: 0.95em;
    line-height: 30px;
    padding: 15px;
    letter-spacing: 1px;
    text-align: center;
    background: #3333CC;
    color: #FFF;
    font-family: Arial, sans-serif;
    font-weight: normal  !important;
    font-style: normal !important;
    font-weight: normal !important;
    
} 

 
@media (min-width: 1200px) {
    
    .bar-logos-cmp{
        display: inline-block;
        position: relative;
        margin-top: -200px;
        margin-bottom: 0px;
    }
    .list-links{
        margin-left: 60px;
    }
}
body{
    font-family: 'Montserrat', sans-serif !important;

}

:root{
	--bauens-blue: #3333cc;
}


.container,
.layout{
	min-height: 100vh !important;
}

.container-pro{
	padding: 1rem 2rem;
	max-width: 1400px;
	margin: 0 auto;

}

.back-white{
	background-color: white;
}

.btn-primary{
	min-width: 100px;
	margin: 0 0.5rem;
	background-color: #3333cc !important;
	background-color: var(--bauens-blue) !important;
	color: white !important;
	border-radius: 3px !important;
	border: none !important;
}

.btn-secondary{
	min-width: 100px;
	margin: 0 0.5rem;
	color: #3333cc !important;
	color: var(--bauens-blue) !important;
	border-radius: 3px !important;
	border: solid 1px #3333cc !important;
	border: solid 1px var(--bauens-blue) !important;
	display:flex !important;
	justify-content: center;
	align-items: center;
}


.btn-close{
	min-width: 100px;
	margin: 0 0.5rem;
	color: #ff4d4f !important;
	border-radius: 3px !important;
	border: solid 1px var(#ff4d4f) !important;
	display:flex !important;
	justify-content: center;
	align-items: center;
}

.btn-secondary svg{
	height: 16px;
	width: 16px !important;
	margin-left: 6px;
}

.input-search .ant-btn{
	border-left: none !important;
	color: #3333cc !important;
	color: var(--bauens-blue) !important; 
	background: white !important;
	border-color: #d9d9d9;
}
.input-search .ant-btn:hover{
	border-color: #d9d9d9;
}
 
.hr, hr{
	border: none;
	margin: 0.8rem 0;
	border-top: solid 1px #ddd !important;
} 

.hr-strong{
	border: none;
	margin: 0.8rem 0;
	border-top: solid 2px #b9b8b8 !important;
} 

@media(max-width: 766px){
	.container-pro{
		padding: 2rem 1rem;
	}
}

/*---------------CLASES GLOBALES----------------*/
.borderr{
	border: solid 1px red;
}
.borderless{
	border: none;
}

.center{
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.flex{
	display: flex !important;
}

.float-right{
	float: right;
}
.float-left{
	float: left;
}
.flex-column{
	display: flex !important;
	flex-direction: column;
}

.flex-end{
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}
.flex-end-top{
	display: flex !important;
	justify-content: flex-end;
	align-items: flex-start;
}
.flex-start{
	display: flex!important;
	justify-content: start!important;
	align-items: center!important;
}

.flex-base{
	display: flex !important;
	align-items: baseline;
	justify-content: space-between;
}

.p-1{
	padding: 1rem;
}
.p-2{
	padding: 2rem;
}
.p-3{
	padding: 3rem;
}

.pt-05{
	padding-top: 0.5rem;
}
.pt-1{
	padding-top: 1rem;
}
.pt-2{
	padding-top: 2rem;
}
.pt-3{
	padding-top: 3rem;
}
.pt-4{
	padding-top: 4rem;
}
.pt-5{
	padding-top: 5rem;
}


.pl-1{
	padding-left: 1rem;
}
.pl-2{
	padding-left: 2rem;
}
.pl-3{
	padding-left: 3rem;
}
.mg-1{
	margin:1rem !important;
}

.pb-1{
	padding-bottom: 1rem;
}
.pb-2{
	padding-bottom: 2rem;
}
.pb-3{
	padding-bottom: 3rem;
}

.pr-1{
	padding-right: 1rem;
}
.pr-2{
	padding-right: 2rem;
}
.pr-3{
	padding-right: 3rem;
}

.mt-1{
	margin-top: 1rem !important;
}
.mt-2{
	margin-top: 2rem !important;
}
.mt-3{
	margin-top: 3rem !important;
}
.mt-4{
	margin-top: 4rem !important;
}
.mb-1{
	margin-bottom: 1rem !important;
}
.mb-2{
	margin-bottom: 2rem !important;
}
.mb-3{
	margin-bottom: 3rem !important;
}
.mb-4{
	margin-bottom: 4rem !important;
}
.ml-1{
	margin-left: 1rem !important;
}
.ml-2{
	margin-left: 2rem !important;
}
.ml-3{
	margin-left: 3rem !important;
}
.ml-4{
	margin-left: 4rem !important;
}
.mr-1{
	margin-right: 1rem !important;
}
.mr-2{
	margin-right: 2rem !important;
}
.mr-3{
	margin-right: 3rem !important;
}
.mr-4{
	margin-right: 4rem !important;
}


.w-100{
	width: 100%;
}

.nolist-style{
	list-style: none;
}


.row-titleProspectos {
	padding: 5px 10px;
}
	

.row-titleProspectos h1{
	font-size: 13pt !important;
}

html, body {
    scroll-behavior: smooth !important;
}

/*Botón limón*/
.btn-limon
{
    background: #E0EF4D;
    color:#000;
    border: none;
}

.btn-limon:hover,
.btn-limon:active,
.btn-limon:focus
{
    background: #dbe770;
    color:#000;
    border: none;
} 


/*Section 1 landing*/
.landing-header-img{
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    display:inline-block;
    height: 37vw;
    vertical-align:bottom;
    min-height: 250px;
}  

 .lnd-sec-1{
    
    height: auto;
    padding: 0px;
    margin: 0px;
    width: 100% !important;
    position: relative !important;
    display: inline-block  !important;
    height:  40vw !important;
    min-height: 260px !important;
    max-height: 450px !important;
    overflow: hidden;
    line-height: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom; 
    background-size: cover;
    color: black;
}  

.lnd-sec-1:before {
    content:'';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(196,196,196,0) 0%, rgba(3,3,3,0.6) 100%);
}

.lnd-sec-2{
    padding-bottom: 10rem;
}

.lnd-sec-4{
    height: auto;
    padding: 0px;
    margin: 0px;
    width: 100% !important;
    position: relative !important;
    display: inline-block  !important;
    height:  20vw !important;
    min-height: 200px !important;
    max-height: 220px !important;
    overflow: hidden;
    line-height: 50%;
}  


.landing-header-img-2{
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    display:inline-block;
    height: 20vw;
    vertical-align:bottom;
    min-height: 200px;
}


.lnd-sec-4 .col-search .iconGotoLanding{
    margin-right: 10px;
}

/*Cards landing*/
.col-landing-card .ant-card{
    min-height: 450px;
    box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    min-height: 150px;
}


/*Tabs landing*/

.col-landing-tabs .ant-tabs{
    min-width: 100%;
}

.col-landing-tabs .ant-tabs-nav-wrap{
    display: inline-block !important;
    position: relative !important;
    width: 100%;
}

.col-landing-tabs .ant-tabs-tab{
    width:calc(100% / 5);
    text-align: center;
    min-width: 150px;
}

.card-ciudad{
    padding: 0px;
}
.card-ciudad{
    background: transparent;
    padding:0px;
    margin:10px;
    border-radius: 10px;
    border-color: transparent transparent;
   
}

.card-ciudad .ant-card-body{
    padding: 0px;
}


.card-ciudad .col-ciudad-img{
        padding: 0px;
        border-radius: 10px;
        display: block;
        position: relative;
        overflow: hidden;
        height: 10% !important;
        min-height: 220px !important;
        max-height: 300px !important;
        
    }
    
    .card-ciudad .col-ciudad-img img{
        padding: 0px;
        border-radius: 10px;
        height: auto !important; 
        width: 100% !important;
        min-height: 300px !important;
 
        -webkit-filter: brightness(60%);
        filter: brightness(60%);
        
}


.card-ciudad .col-ciudad-info{
    background: transparent;
    position: relative;
    margin-top: -70px;
    color:#FFF;
    text-align: left;
    margin-left: 20px;
} 


.card-ciudad .col-ciudad-info h3{
    margin-bottom: 0px;
} 


/*Radio landing*/
.landing-content .itemRadio{
    background: #C8D645;
    padding: 2px 0px;
    border-radius: 5px;
}

.landing-content .itemRadio .ant-radio-button-wrapper{
    background: transparent;
    border-radius: 5px;
    border: none;
    color:#FFF;
    margin: 0px 2px;
    min-width: 80px;
}


.landing-content .itemRadio .ant-radio-button-wrapper-checked{
    background: #FFF;
    color:#000;
    margin: 0px 2px;
}

.landing-content .itemRadio .ant-radio-button-wrapper::before,
.landing-content .itemRadio .ant-radio-button-wrapper-checked::before{
    background: transparent;
}



.col-search{
    /* background-color: red; */
}

.col-search .landing-page-search-input{
    /* min-width: 250px; */
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 1px rgba(255, 255, 255, 0.25);

    border:  none !important;
}


.col-search .landing-page-search-input input{
    /* min-width: 250px; */
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    border-radius: 6px 0px 0px 6px;
}


.col-search .ant-input-search-button{
    border:  none !important;

}

.col-search .landing-page-search-input .ant-input-group-addon{
    border-radius: 0px 6px 6px 0px !important;
}
/*Search landing*/
/* .col-search{
    background: white;
    width: 50%;
    margin: auto;
    max-width: 500px;
    border-radius: 5px;
    min-height: 50px;
    vertical-align: middle;
}

.col-search .inputSearch{
    padding: 0px;
    float: left;
    width: calc(100% - 35px);
    min-width: 430px;
    border: none !important;
}


.col-search .inputSearch input{
    min-height: 50px !important;
    border: none;
}

.col-search .inputSearch input::placeholder{
    color:#9E9E9E;
}

.col-search .inputSearch .ant-input-group-addon{
    display: none;
    visibility: hidden;
}

.col-search .btnSearch {
    width: 35px;
    padding: 0px;
    margin: 0px;
    min-height: 50px;
    border: none;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 0px;
}

.col-search .iconSearchLanding{
    float: left;
} */

.link-todas-cuidades{
    text-align: right;
    margin-right: 25px;
    padding: 15px 0px;
}

.row-bar-sub-blue{
    display: inline-block;
    margin: auto;
    width: 100%;
    max-width: 1150px;    
}


/*Globales*/
.text-white{
    color:#FFF !important;
} 

.center{
    text-align: center;
}
.left{
    text-align: left;
}

.mt-5{
    margin-top: 2%;
}

.pd-3{
    padding: 3rem;
}

.mg-10{
    margin:10px !important;
    padding:10px !important;
}

.pb-30{
    padding-bottom:30px !important;
}

.pd-10{
    padding: 10px;
}

.mxh-25prs{
    height: 20%;
}



.mxw-345px{
    max-width: 345px;
}
.mxw-1300px{
    max-width: 1300px;
}

.mxw-1400px{
    max-width: 1400px;
}

.wd-100prs {
    width: 100%;
}

.wd-70prs {
    width: 70%;
}

.main-landing-row{

}

@media (min-width: 1200px) {
    .xl-align-left{
     text-align: left !important;
     margin-top: 30px;
    }
 
}
.card-hoverable:hover{
	cursor: pointer;
	box-shadow: 0px 5px 10px #bbb;
}	

.card-inmueble{
	border-radius: 0px;
	box-shadow: 0px 5px 10px #ddd;
	border: none;
	transition: box-shadow 0.4s ease-in-out;
	height: 100%;
	overflow: hidden;
}

.card-inmueble .ant-card-body{
	padding: 0;
	background-color: transparent;
	display: flex;
	flex-flow: column;
	height: 100%;
}

.card-inmueble .card-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 230px;
  position: relative;
}

.card-inmueble .card-tag{
	position: absolute;
	top: 195px;
	left: 2%;
	font-size: 16px;
	font-weight: 300;
	border-radius: 5px;
	padding: 0.1rem 0.5rem;
}

.card-inmueble .card-body{
	min-height: 240px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	flex: 1 1 auto;
}


.card-inmueble .card-title{
	font-weight: 300;
}

.card-inmueble .card-text{
	font-size: 12px;
	margin:0 0 ;
	text-align: justify;
	height: 95px !important;
	-webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.card-inmueble .card-footer {
	margin-top: 1rem;
}

.card-inmueble .card-footer span{
	display: inline-block;
	width: 25%;
	text-align: center;
}

@media(max-width:	460px ){

	.card-inmueble .card-body{
		height: 300px !important;
	}

	.card-inmueble .card-footer .card-info{
		display: flex;
	}
	.card-inmueble .card-footer .card-info svg{
		display: none;
	}
}

@media(max-width: 1200px){
	.card-inmueble .card-body{
		height: 200px;
	}
}

@media(min-width: 1201px) and (max-width: 1420px){
	.card-inmueble .card-body{
		min-height: 300px;
	}
}

.card-inmueble .card-footer .card-direction{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	text-align: left;
}

.card-inmueble .card-footer svg{
	margin-right: 5px;
	height: 16px;
}

.card-inmueble .card-footer div.bl{
	border-left: solid 1px #ddd;
}

.card-inmueble .card-footer .card-info {
	display: flex;
	font-size: 12px;
}

.card-inmueble .card-footer .card-info  .ant-skeleton.ant-skeleton-element.ant-skeleton-active{
	width: 100%;
}

.card-inmueble .card-footer .card-info div{
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}


/*CARD-INMUEBLE SMALL*/


.card-inmueble-small{
	border-radius: 10px;
	box-shadow: 0px 5px 15px #ddd;
	border: none;
	margin-bottom: 1rem;
	overflow: hidden;
}

.card-inmueble-small .ant-card-body{
	padding: 0;
	background-color: transparent;
}

.card-inmueble-small .card-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  position: relative;
}

.card-inmueble-small .card-tag{
	position: absolute;
	top:85%;
	left: 2%;
	font-size: 16px;
	font-weight: 300;
	border-radius: 5px;
	padding: 0.1rem 0.5rem;
}

.card-inmueble-small .card-body{
	height: 230px;
	padding: 1rem;
	position: relative;
}

.card-inmueble-small .card-title{
	font-weight: 300;
	font-size: 19px;
}

.card-inmueble-small .card-text{
	font-size: 12px;
	margin:0 0 ;
	text-align: justify;
	height: 95px !important;
	overflow: hidden;
	text-overflow: ellipsis;

}

.card-inmueble-small .card-footer {
	position: absolute;
	bottom: 10px;
	right: 10px;
	left: 10px;
}

.card-inmueble-small .card-footer span{
	display: inline-block;
	width: 50%;
	text-align: center;
}

.card-inmueble-small .card-footer .card-direction{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.card-inmueble-small .card-footer svg{
	margin-right: 5px;
	height: 12px;
}

.card-inmueble-small .card-footer div.bl{
	border-left: solid 1px #ddd;
}

.card-inmueble-small .card-footer .card-info {
	display: flex;
	font-size: 10px;
}

.card-inmueble-small .card-footer .card-info div{
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/*--------------------------CARD INMUBLE PRO - ( Y BIG)-------------------------------------*/
/*------------------------------------------------------------------------------------------*/

.card-inmueble-pro,
.card-inmueble-pro-big{
	border-radius: 10px;
	box-shadow: 0px 5px 10px #ddd;
	border: none;
	transition: box-shadow 0.4s ease-in-out;
	overflow: hidden;
}

.card-inmueble-pro .ant-card-body,
.card-inmueble-pro-big .ant-card-body {
	padding: 0;
	background-color: transparent;
}

.card-inmueble-pro .card-image,
.card-inmueble-pro-big .card-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 250px;
  height: 100%;
  position: relative;
}

.card-inmueble-pro .card-image:before {
	content:'';
	position: absolute;
    top: 25%;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(180deg, rgba(196,196,196,0) 0%, rgba(3,3,3,0.89) 100%);
}

.card-inmueble-pro .card-title{
	font-size: 20px;
	color: white;
	font-weight: 500;
}

.card-inmueble-pro-big .card-title{
	font-weight: 500;
	font-size: 20px;
	color: #000000;
}


.card-inmueble-pro .card-direction{

	font-size: 13px;
	color: white;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.card-inmueble-pro-big .card-direction{
	font-weight: normal;
	font-size: 16px;
	color: #9D9D9D;
	display: flex;
	margin-bottom: 10px;
}

.card-inmueble-pro .card-direction svg{
	fill: white;
	height: 16px;
	margin-right: 8px;
}

.card-inmueble-pro .card-direction *{
	fill: white;
}

.card-inmueble-pro .position-down{
	position: absolute;
	bottom: 10px;
	left: 10px;
	right: 10px;
}

.card-inmueble-pro-big .card-direction svg{
	fill: #9D9D9D !important;
	height: 16px;
	margin-right: 8px;
}

.card-inmueble-pro-big .card-direction *{
	fill: #9D9D9D !important;
}

.card-inmueble-pro-big .card-text{
	text-align: justify;
	font-weight: normal;
	font-size: 12px;
	color: #000000;
	min-height: 90px;
}

.card-inmueble-pro-big .card-price{
	font-weight: 600;
	font-size: 24px;
	color: #000000;
	margin: 0;
}

.card-inmueble-pro-big .card-price small{
	font-weight: 400;
	font-size: 22px;
	color: #000000;
	margin: 0;
}

.card-inmueble-pro .card-body{
	padding: 0.5rem;
}

.card-inmueble-pro .col-btns{
	display: flex;
	align-items: center;
}


.card-inmueble-pro .btn-card,
.card-inmueble-pro-big .btn-card {
	min-width: 18%;
	padding: 0 10px 0 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 24px;
	margin: 0 5px 0 0;
	border-radius: 5px;
	font-size: 13px;
}

.card-inmueble-pro .btn-card  svg,
.card-inmueble-pro-big .btn-card  svg{
	width: 28px;
	height: 28px;
}

.card-inmueble-pro .btn-trash,
.card-inmueble-pro-big .btn-trash{
	width: 30px;
	height: 30px;
	padding: 0;
	position: relative;
	border: none;
}

.card-inmueble-pro .btn-trash svg,
.card-inmueble-pro-big .btn-trash svg{
	width: 30px;
	height: 30px;
}


.card-inmueble-pro .btn-edit,
.card-inmueble-pro-big .btn-edit{
	color: #2A8BF2;
	border: none;
	width: 30px;
	height: 30px;
	position: relative;
	top: 2px;
	color: lightblue 
	
}








.site-collapse-custom-collapse{
	background: white;
}

.site-collapse-custom-panel .ant-collapse-header {
	min-height: 50px !important;
}

.panelColapsed,
.panelNoColapsed{
	width:  10px;
	height: 10px;
	padding: 0px;
	margin: 0px;
	display: inline-block;
	position: absolute;
	top: 0px !important;
}
  
.panelNoColapsed svg{
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
}


.col-comunidad{
	padding: 10px;
} 

.row-comunidad{
	display: inline-block;
	width: 90%;
}

.col-img-comunidad{
	width: 100%;
	display: block;
	border-radius: 5px;
}

.imgCasa{
	width: 100%;
	border-radius: 5px;
	border:solid 1pzx #555;
	
}

.col-info-comunidad{
	padding-top: 15px;
}

.iconLogoCircle{
	float: right;
	display: block;
	position: relative;
	top:-16px;
	right: -10px;
	z-index: 1;
}

.text-cmn-nombre,
.text-cmn-comunidad,
.text-cmn-estado{
	font-style: normal !important;
	line-height: 12px !important;
	color: #000000;
	font-weight: bold !important;
	margin-bottom: 0px;
	padding-bottom: 0px;
}


.text-cmn-nombre .ant-typography,
.text-cmn-comunidad .ant-typography,
.text-cmn-estado .ant-typography,
.col-info-comunidad h5,
.col-info-comunidad .ant-col
{
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
}


.text-cmn-nombre{
	font-size: 1em !important;
}

.text-cmn-comunidad{
	font-weight: normal !important;
	font-size: 10px !important;
}

.text-cmn-estado{
	font-size: 10px !important;
}

.mr-20px{margin-right: 20px;}

.imgPerfilAgente{
	 
	 height: 80px;
	 width:  80px;
	
}
.dropdown-menu{
	padding: 0.5rem 1rem 1rem 1rem;
	max-width: 400px;
	min-width: 300px;
	max-height: 600px;
	width: 100%;
	overflow-y: auto; 
}

.dropdown-menu .flex-column{
	padding-bottom: 1rem;
}

.dropdown-menu .ant-typography.dropdown-title{
	font-size: 14px;
	margin-bottom: 1rem;
	font-weight: 700 !important;
	color:#000 !important;
}


.dropdown-menu .dropdown-check{
	font-size: 12px;
	margin-top: 0.4rem;
}


.dropdown-radio{
	width: 100%;
}

.dropdown-menu .dropdown-radio .ant-radio-button-wrapper{
	padding: 0 8px;
	font-size: 13px;
	min-width: 35px;
	text-align: center;
}


/*radio buttons de colores*/

.dropdown-collapse .radio-green .ant-radio-checked .ant-radio-inner,
.dropdown-collapse .radio-green .ant-radio-inner,

.simple-radio-green .ant-radio-checked .ant-radio-inner,
.simple-collapse .radio-green .ant-radio-inner 
{
	border-color: #87d068;
	border-width: 3px;
}

.dropdown-collapse .radio-green .ant-radio-inner::after,
.simple-radio-green .ant-radio-inner::after
{
	background-color: #87d068;
}

.simple-radio-green .ant-radio-inner::after{
	display: none;
}






.dropdown-collapse .radio-purple .ant-radio-checked .ant-radio-inner,
.dropdown-collapse .radio-purple .ant-radio-inner,

.simple-radio-purple .ant-radio-checked .ant-radio-inner,
.simple-collapse .radio-purple .ant-radio-inner {
	border-color: #7878dd;
	border-width: 3px;
}

.simple-radio-purple .ant-radio-inner::after,
.dropdown-collapse .radio-purple .ant-radio-inner::after{
	background-color: #7878dd;
}


.simple-radio-purple .ant-radio-inner::after{
    /* position: relative;
    left: 1px;
    top: 0.8px; */
	display: none;

}





.dropdown-collapse .radio-yellow .ant-radio-checked .ant-radio-inner,
.dropdown-collapse .radio-yellow .ant-radio-inner,

.simple-radio-yellow .ant-radio-checked .ant-radio-inner,
.simple-collapse .radio-yellow .ant-radio-inner {
	border-color: #dfff19;
	border-width: 3px;
}
.dropdown-collapse .radio-yellow .ant-radio-inner::after{
	background-color: #dfff19;
}

.simple-radio-yellow .ant-radio-inner::after{
    /* position: relative;
    left: 1px;
    top: 0.8px; */
	display: none;

}

.dropdown-collapse  .ant-radio-inner::after{
	width: 13px;
	height: 14px;
	top: -1px;
	left: -1px;
}


/*colapasable*/

.dropdown-collapse{
	width: 400px;
	padding: 0px;
}

.dropdown-collapse .ant-checkbox-group,
.dropdown-menu .ant-checkbox-group{
	width: 100%;
	margin-left: 1rem;
	margin-top: 0.5rem;
}

.dropdown-collapse .ant-checkbox-group-item,
.dropdown-menu .ant-checkbox-group-item,
.col-inmueble .ant-checkbox-group-item{
	margin: 0 !important;
	font-size: 12px;
}

.dropdown-collapse .ant-checkbox-wrapper,
.dropdown-menu .ant-checkbox-wrapper,
.col-inmueble .ant-checkbox-wrapper{
	width: 50%;
}


/*DROPDOWN DE TIPOS*/
/*3333CC*/
.dropdown-tipo{
	max-width: 250px;
	padding: 0.5rem;
}

.dropdown-tipo .btn-tipo{
	height: 100%;
	width: 100%;
	display:flex;
	align-items: center;
	/* border: 2px solid transparent; */
	width: auto;
	padding: 5px;
}

.dropdown-tipo .btn-tipo:hover{
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

	/* border: 2px solid red; */
}

.dropdown-tipo .btn-tipo .svg-div{
	position: relative;
}

.dropdown-tipo .btn-tipo .svg-div svg{
	height: 65px;
}

.dropdown-tipo .btn-tipo:hover svg {
	fill: #7878ce !important;
}

.dropdown-tipo .btn-tipo .svg-div.active svg{
	fill: #3333cc;
}

.dropdown-tipo .btn-tipo .svg-div .svg-check{
	display: none;
}

.dropdown-tipo .btn-tipo .svg-div .svg-check.active{
	position: absolute;
	left: 65%;
	top: 60%;
	display: block;
	height: 20px;
}

.dropdown-tipo .btn-tipo .div-text{
	padding-left: 1rem; 
	text-align: left;
	word-wrap: break-word;
}





/**  ESTILOS EXTRAS ***/
.dropdown-menu.extras{

	width: 100%;
	min-width: 420px;

}

.dropdown-menu.extras .extra-title-section{
	/* font-weight: bold; */
	margin-top: 5px;
}

.dropdown-menu.extras .extra-slider{
	
	margin-top: 1em;
	/* margin-bottom: 12px; */
}
.dropdown-menu.extras .input-search 
{
	margin-bottom: 2em;
}

.dropdown-menu.extras .checkbox-extra span{
	font-size: 12px !important;
}

.dropdown-menu.extras .checkbox-extra{
	margin-bottom: 3px;
}

.dropdown-menu.extras span.quantity-extra{

	font-weight: 300;
	font-size: 10px !important;
}

.dropdown-menu.extras span.quantity-extra svg{
	position: relative !important;
	top:  0;
	bottom: -20px !important;
}


.tag-extra{
	display: unset;
	position: relative;
	left: 5;
	padding: 0px 3px 0px 4px;
	border: none !important;
	background-color: unset !important;
	font-weight: 300;
	/* border: 1px solid rgba(0,0,0,.060); */
}

.input-extra-option input{
	font-size: 12px;
}

.disabled-tabpane {

	cursor: pointer !important;	
}
/* .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow */
/* .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow { */
    /* color: rgba(0, 0, 0, 0.25); */
    /* cursor: not-allowed; */
/* } */
/*--------------CSS PARA EL MODAL DE INMUEBLE------------------*/
.col-imagen .card-image-big{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  position: relative;
  margin-bottom: 1rem;
}

.col-imagen .card-image-med{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
  position: relative;
  margin-bottom: 12px;
}

.col-imagen .card-image-big-blur{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  position: relative;
  margin-bottom: 1rem;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.col-imagen .image-content{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.col-imagen .left-circle{
	left: 5%;
}

.col-imagen .right-circle{
	right: 5%;
}

.col-imagen .left-circle,
.col-imagen .right-circle {
	position: absolute;
	top: 45%;
	z-index: 100;
	border: none;
	padding: 0;
	height: 35px;
	width: 35px;
	border-radius: 20px;
	text-align: center;
	background: rgba(0,0,0,0.5);
	color: white;
}

.col-imagen .image-content .text-white{
  margin: 0 0 1rem 0 !important;
}

.col-imagen .image-content .btn-yellow-image{
	border: none !important;
	background-color: #e0ef4d;
	color: black;
	height: 36px;
}

.col-inmueble{
	background-color: white;
	padding: 1rem 1.5rem;
}

.col-inmueble .col-btns-top{
	display: flex;
	justify-content: flex-end;
	align-items: center;

}

@media(max-width:  450px){

	.col-inmueble .btn-secondary{
		margin:0 2px;
	}

	.col-inmueble .btn-secondary svg{
		display: none;
	}

	.col-inmueble .col-btns-top{
		display: flex;
		justify-content: center;
	}
}

.col-inmueble .text-price{
	font-size: 26px;
	font-weight: 500;
}

.col-inmueble .text-price small{
	font-weight: 400;
}

.col-inmueble .text-svg *{
	fill: var(--bauens-blue);
}

.col-inmueble .text-svg svg{
	height: 40px;
	width: 50px;
}

.col-inmueble .text-svg{
	color: var(--bauens-blue);
	display: flex;
	align-items: center;
}

.col-inmueble .text-info{
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
	min-width: 100px;
}

@media(max-width: 576px){

	.col-inmueble .text-info{
		justify-content: flex-start;
	}
}


.col-inmueble .text-info.left{
	justify-content: flex-start !important;
	line-height: 30px;
	font-size: 16px;
}

.col-inmueble .text-info.left b{
	font-size: 20px !important;
	margin: 0 0.3rem;
	font-weight: 500;
}

.col-inmueble .text-direction{
	display: flex;
	margin: 0.5rem 0;
	font-size: 16px;
}

.col-inmueble .text-direction svg{
	margin-right: 0.5rem;
}

.text-info svg{
	margin-right: 0.5rem;
}

.col-inmueble .btn-black{
	margin-bottom:1rem;
	width: 100%;
	border-color: black;
	color: black;
	height: 36px;
}

.col-inmueble .btn-yellow{
	margin-bottom:1rem;
	width: 100%;
	border: none !important;
	background-color: #e0ef4d;
	color: black;
	height: 36px;
}

.img-mapa{
	max-width: 100%;
	max-height: 400px;
}

.col-inmueble .text-description{
	font-size: 12px;
}

.col-inmueble .text-strong{
	font-size: 20px;
	font-weight: 900;
}

.col-inmueble .text-strong-blue{
	font-size: 20px;
	color: var(--bauens-blue);
	font-weight: 100;
}

.col-inmueble .ant-checkbox-group{
	width: 100%;
	margin-left: 2rem;
	margin-top: 0.5rem;
}

.col-inmueble .text-rate{
	color: #1d7bff;
	font-weight: 700;
	font-size: 20px;
}

.car-list-element{
	padding-top: 5px;
}
.dot-map{
    border-radius: 100%;
    width: 20px;
    height: 20px;
    border: 4.5px solid white;
    box-shadow: 0px 0px 7px 0px rgba(138,138,138,1);
}

.color-venta{
    background: #88cfb6 !important;
}

.color-renta{
    background: #7878dd !important;
}

.color-vendida{
    background: #dfff19 !important;
}


.marker {
    /* -webkit-box-shadow: 0px 0px 9px 4px rgba(0,0,0,0.23); 
    box-shadow: 0px 0px 9px 4px rgba(0,0,0,0.23); */
    width: 50px;
    height: 50px;
}

.img-location{
    padding: 5%;
    margin-left: -5px !important;
}


.info-location {
    padding-left: 12px !important;
}

.info-location .card-title{
    font-size: 10pt !important;
}

.info-location .ant-typography{
    margin-bottom: 1px !important;
}



.dropdown-tipo .btn-tipo:hover  
{
     background: white;
     border: 0px solid transparent;
    box-shadow: none!important;
}


.dropdown-tipo .btn-tipo .svg-div  
{
    position: relative !important;
    width: 20px !important;
}

.dropdown-tipo .btn-tipo .svg-div .svg-check
{
    position: relative !important;
    background: transparent !important;
    margin-left: -12px !important;
    margin-right: 0px !important;

}
.row-filtros{
	background-color: white;
	min-height: 50px;
	padding: 1rem 2rem;
	border-bottom: solid 1px #ddd;
	margin-left: 0px !important;
	margin-right: 0px !important; 
}

.row-filtros .input-select,
.row-filtros .input-number{
	width: 100%;
}

.col-header{
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.select-med{
	width: 180px;
}



.btn-emailNotification{
	margin-top: 50px;
	background-color:  rgb(135, 208, 104);
	color: #FFF;
	border-radius: 5px;
	min-width: 480px !important;
	max-width: 600px !important;
	min-height: 115px !important;
	display: inline-block;
	position: relative;
	text-align: center;
	font-size: 11pt;
	padding: 20px;
}
 

.infinite-scroll-component__outerdiv{
	width: 100%  !important;
}

.infinite-scroll-component {
	overflow-x: hidden !important;
}
.list-content .row-bg-image{
    height: auto;
    background-repeat: no-repeat !important;
    background-size: cover;
    text-align: center;
    background-position-y: 20%;
    position: relative;
    min-height: 20vw;
}

.row-bg-image h2{
    margin-top: 10% !important;
}

.cnt-center{
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 15px 0px;
}


.list-card-agente{
    border-radius: 5px;
    min-height: 50px;
    margin-bottom: 20px;
    /* box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.25); */
    border: none !important;
    width: 90%;
}


.list-card-agente .ant-card-body{
    padding: 0px;
}

.col-img-agente{
    width: 40%;
    padding-top: 10px;
}


.imgCircle{
    border-radius: 100px;
    overflow: hidden;
}

.cnt-center .text-blue{
    color:#006AFF;
}

.col-info-agente   .text-blue{
    margin-bottom: 0px !important;
    font-size: 1rem;
    margin-bottom: 0.2rem !important;
}

.col-info-agente-2   .text-blue{
    margin-bottom: 0px !important;
    font-size: 1rem;
    margin-bottom: 0.2rem !important;
}

.iconImgCircle{
    position: relative;
    bottom: 0;
    z-index: 1;
    margin-left: -40px;
    margin-bottom: -70px;
}


.col-info-agente{
    width: 60%;
    text-align: left;
    font-size: 0.65rem;
    padding: 5px 0px;
    padding-left: 5px;
}

.col-info-agente-2 {
    width: 60%;
    text-align: left;
    font-size: 0.65rem;
    padding: 5px 0px;
    padding-left: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.col-info-agente .ant-col {
    margin-bottom: 3px;
}

.col-card-descripcion .ant-typography{
    text-justify: auto !important;
}

.tabs-agentes{
    color:#3333CC;
}

.tabs-agentes .IconUbicacion{
    display: block;
    margin-right: 8px;
    margin-top: -28px;
    float: right;
    z-index: 10;
    position: relative;
}

.tabs-agentes .search-ubicacion .anticon-search{
    display: none  !important;
    visibility: hidden !important;
}

 .iconImgInsignia{
     float: right;
     height: auto;
     width: auto;
     max-width: 20%;
     min-width: 60px;
     display: block;
     position: absolute;
     right: 0;
     z-index: 1;

 }
 
 .row-iconimgInsignia{
    max-width: 90px !important;
 }

 .imgMapa{
     width: 98%;
 }


 .row-card-agente {
  width: 100%;
 }

 /*Testimonios*/
 .carusel-testimonios li {
    width:  11px !important;
    height: 11px !important;
}


 .carusel-testimonios button{
    background: #3333CC !important;
    border-radius: 100px !important;
    width:  7px !important;
    height: 7px !important;
}

 
.row-testimonio{
    width: 90% !important;
    margin: auto !important;
    max-width: 500px;
    padding: 5px 10px !important;
}

 .col-tst-fecha {
    text-align: right;
    padding:10px ;
}

 .col-tst-fecha .ant-typography{
     color: #006AFF;
    font-size: 12px;
    
 }


 .col-tst-autor,
 .col-tst-testimonio {
     text-align: left;
 }


.col-tst-testimonio  .ant-typography{
    font-style: italic;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.col-tst-autor .ant-typography{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}

.list-agentes  .ant-list-item {
    border-bottom-color: transparent !important;

}


/*Globales*/
.mg-auto{
    margin: auto;
}

.ml-50px{
    margin-left: 50px;
}

.pdr-10px{
    padding-right: 10px !important;
}

.pdl-10px{
    padding-left: 10px !important;
}

.mxw-1240px{
    max-width: 1240px;
}

.ml-50px{margin-left: 50px;}

.pd-0px{
    padding: 0px !important;
}
 
.pd-10px{
    padding: 10px !important;
}

.btn-gh-blue{
    color:#3333CC !important;
    border-color:  #3333CC !important;
}

.right{
    text-align: right;
}

.card-btn-goto{
    font-family:  sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3333CC;
    padding: 5px 15px 10px 15px;
}

.card-btn-goto svg{
    margin-left: 10px;
    top: 3px;
    position: relative;
}

.text-link-blue,
.text-link-blue:hover,
.text-link-blue:focus,
.text-link-blue:active{
    float: left;
    font-family:  sans-serif;
    font-style: normal;
    font-weight:600;
    font-size: 16px;
    line-height: 24px;
    color: #3333CC;
    margin-top: -17px;
    margin-left: 0px;
    width: 150px;
    text-align: left;
    padding-left: 30px;
    min-width: 300px;

}
 
.menuLista  {
    margin-bottom: 12px;
}


.menuLista .ant-menu-item  h4.ant-typography,
.menuLista  .ant-typography {
    box-sizing: border-box !important;
    align-items: left !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    color: #3333CC !important;
}


/*Vista individual*/
.col-contenedor-border{
    border: solid 1px #D3DEE8;
    width: calc(100% - 20px) !important;

    margin: 10px;
    padding: 10px;
}

.col-agente-img{
    text-align: left;
    max-width: 200px;
    float: left;
}

.col-agente-img .img-perfil{
    height: 200px;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover; 
    position: relative;
}

.spanRedesSociales{
    position: absolute;
    background:#3333CC ;
    display: block;
    float: left;
    z-index: 10;
    width: 75px;
    height: 30px;
    padding: 5px;
    bottom: 12px;
    left: 0px;
}

.col-agente-info{
    width: calc(100% - 200px);
}

.col-agente-info .col-info{
    margin-bottom: 0.5rem;
}

.col-agente-info .col-info .svg-item{
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}


.imgFotoAgente{
    width: 100%;
    height: auto;
}

.col-agente-info{
    text-align: left;
    padding-left: 10px;
}



.spanRedesSociales svg{
    margin: 0px 8px;
}

.btn-agenteFavorito{
    background: transparent;
    border-radius: 100px;
    width:  34px;
    height: 34px;
    padding: 0px;
    border-color: transparent;

    float: right;
    position: relative;
    display: block;
    right: 0px;
    top:-40px
}


.col-contenedor-border .list-card-agente{
    border-color: transparent !important;
    box-shadow: none !important;
    width: 100% !important;
    padding: 0px !important;
    
}

.col-contenedor-border .col-card-descripcion{
    text-align: left !important;
    text-justify: auto; 
    padding-left: 5px;
    margin-top: 10px;
}

.muesca-blue{
    width: 60px;
    height: 4px;
    background:#3333CC ;
    display: block;
    margin-bottom: 10px;
}

.mb-10px{
    margin-bottom: 10px;
}

.mt-50px{
    margin-top: 50px;
}

.pt-10px{
    padding-top: 10px;
}

.col-carusel-detAgente{
    padding: 10px;
}

.col-carusel-detAgente .carusel-testimonios{
    width: 100% !important;
    display: block;

}

.col-carusel-detAgente .row-testimonio{
    margin: auto !important;
    width: 100% !important;
    padding: 10px;
    margin-bottom: 15px !important;
    min-width: 100% !important;
    text-align: center;
}

.col-carusel-detAgente .col-info{
    display: block !important;
    width: 100% !important;
    margin-bottom: 50px !important;
    margin: auto !important;
    min-width: 100% !important;
    padding: 20px 20% !important;
}


.col-carusel-detAgente .col-txt-fecha,
.col-carusel-detAgente .col-txt-testimonio,
.col-carusel-detAgente .col-txt-autor{
    margin-bottom: 10px;
}


.col-carusel-detAgente .col-txt-fecha{
    text-align: right;
    color: #6100FF !important;
}


.col-carusel-detAgente .col-txt-fecha .ant-typography{
    color: #6100FF !important;
}

.col-carusel-detAgente .col-txt-testimonio{
    text-align: left;
    font-weight: bold;
    font-style: italic;
}

.col-carusel-detAgente .col-txt-autor{
    font-weight: normal !important;
    text-align: left;
}
.bg-white{
    background: #fff;
}

.bg-gray{
    background: rgba(0, 0, 0, 0.02);
}

.bg-purple{
   background: rgba(51, 51, 204, 0.1);
}

.list-messages{
    min-height: 277px;
    max-height: 277px;
    width: 100%;
    height: 277px;
    overflow-y: auto;
}

.list-messages > div .ant-list-empty-text{
    height: 240px!important;
}

.shadow-sm{
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.list-item-property-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #262626;
}
.list-item-property-prospecto{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    color: #262626;
}

.property-actions .ant-comment-content-author,.property-actions .ant-comment-actions {
    justify-content: space-between;
}
.property-actions ul li{
    vertical-align: middle;
    display: inline-flex;
    justify-content: flex-end;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.tiny{
    font-size: 10px;
}
.btn-property-files{
    border-radius: 5px;
    background: #B5CFE7;
    border-color:#2A8BF2;
    float: left;
    
}
.btn-property-files span{
    color:#2A8BF2;
    font-weight: 600;
}
.btn-property-group {
    display: inline-flex;
}
.btn-property-action-medium{
    border-radius: 5px;
    min-width: 45px;
    min-height: 25px;
    height: auto;
    font-size: 0.70rem;
    padding: 0px 5px;
}
.btn-property-action-large{
    border-radius: 5px;
    min-width: 100px;
    min-height: 20px;
    max-width: 200px;
    max-height: 20px;
    
    font-size: 0.70rem;
    padding: 0px 5px;
}
.btn-property-action {
    border-radius: 5px;
    margin-left:3px;
    min-width: 52px;
    min-height: 20px;
    width: auto;
    font-size: 0.70rem;
    padding: 0px 5px;
}

.align-center{
   
    display: flex;
	align-items: center;
}
.align-middle{
    vertical-align: middle;
}
.btn-yellow{
    background: #E0EF4D;
    color: #000;
}

.btn-blue {
    background: #3333CC;
    color: #fff;
    border: none;
    margin-right: 0.5rem;
}

.btn-yellow{
    background: #E0EF4D;
    color: #000;
    border: none;

}

.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active{
    background: #3333CC;
    color: #fff;
    border: none;
}
.text-blue{
    color: #3333CC;
}

.input-search-addon-blue{
    max-width: 500px;
}
.input-search-addon-blue .ant-input-group-addon{
    background: transparent;
    display: none;
}
.input-search-addon-blue .ant-input-group-addon button{
    /* background: #3333CC; */
    background: transparent;
    color: #3333CC!important;
    border: none;
    box-shadow: none;

}

/*Section 1*/
.db-sec-1{
       background-position: center;
       background-size: cover;
       background-repeat: no-repeat;
       background-position: 50%;
       position: relative;
       height: auto !important;
       height: 50vw  !important;
       min-height: 450px !important;
       max-height: 650px !important;
       margin-bottom: -10px;
}  

.row-db-sec-1{
    width: 70%;
    margin: auto;
    padding: 5px;
    height: 250px;
    margin-top: 7%;
    max-width:1000px;
}

.row-db-sec-1 .ant-col{
    margin: 0px !important;
    padding:0px !important;
    height: auto !important; 
    margin-bottom: 1.5vw !important;
}


.row-db-sec-1 .ant-col h4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}


.row-db-sec-1  .btn-limon{
margin-top: 25px;
}


.btn-db-down{
    display: block;
    position: absolute !important;
    bottom: 10px !important;
    width: 65vw !important;
    max-width: 1000px;
}

.db-sec-2{
    padding: 100px 10px;
    width: 100%;
    margin:auto;
    max-width: 1200px;
}

.db-imgClientes{
    width: 90%;
    height: auto;
}

.pb-30px{
    padding-bottom: 30px !important;
}
/*-------------------*/

.db-sec-3{
    background-position: center;
       background-size: cover;
       background-repeat: no-repeat;
       background-position: 50%;
       position: relative;
       height: auto !important;
       height: 20vw  !important;
       min-height: 350px !important;
       max-height: 500px !important;
       margin-bottom: -5px !important;
}

.row-db-sec-3{
    width: 90%;
    margin: auto;
    padding: 5px;
    height: 200px;
    margin-top: 4%;
    max-width:1500px;
}

.row-db-sec-3 .ant-col{
    margin: 0px !important;
    padding:0px !important;
    height: auto !important; 
    margin-bottom:0px !important;
}

.row-db-sec-3 .ant-col h4,
.row-db-sec-4 .ant-col h4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 0px!important;
    margin-bottom: 15px !important;
}

.row-db-sec-3 .ant-col h2{
    margin-bottom: 50px;
}

.row-db-sec-3 .text-blue{color : #3333CC!important}
.row-db-sec-3 .text-red {color : #EB4335 !important}


.db-imgLogoKey{
    width: 70px;
}

.db-sec-4{
    margin:60px 0px 10px 0px;
    padding:10px !important;
    display: block !important;
    position: relative;
    min-height: 450px !important;
}

.row-db-sec-4{
    width: 100%;
    max-width: 1000px;
    margin: auto !important;    
    min-height: 400px !important;
    height: auto !important;
    
}

.col-carusel{
    margin-bottom: 10PX;
}

.row-db-sec-4 .ant-carousel{
    min-height: 250px;
    overflow: hidden;
    position: relative;
    display: block;
    padding: 10px;
}

.col-img-cliente{
    width:  100px;
    height: 100px;
    border-radius: 100px;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center !important;
}
 

.row-cliente{
    width: 100%;
    display: inline-block;
    padding:10px;
}



.row-cliente .col-img,
.row-cliente .col-info{
    float: left;
    margin: auto !important;
    width: 100%;
}

.row-cliente .col-img{
    margin-bottom: 15px !important;
    margin-top: 5px;
}
.row-cliente .col-info{
    padding-left: 5px;
}

.row-cliente .iconLogoCircle{
    
    display: block;
    z-index: 1;
    margin-top: 60px;
    margin-right: -3px;

}

.row-cliente .col-txt-comentario{
    font-style: italic !important;
    font-size: 15px;
    
}
.card-tipo-text{
    font-weight: 700;
    font-size: 0.8rem;
    flex-wrap: wrap!important;
    word-wrap: break-word!important;
}
.shadow-card{
    background: #FFFFFF;
    border: 1px solid #D3DEE8;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(0, 115, 225, 0.06);
    border-radius: 4px;

}
.card-property-type{ 
    max-width: 350px;
}
.card-property-type-image{
    height: 100px;
    width: auto;
    border-radius: 50px;

}
.row-cliente .col-txt-cliente h5{
 
    font-family: Montserrat;

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #2A2C30 !important;
}

.row-cliente .col-txt-puesto{
    font-size: 12px !important;
}


.regular-subtitle{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px!important;
    line-height: 66px;
    color: #000000;


}

.card-title .ant-card-head-title{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    /* identical to box height, or 264% */
    color: #000000;


}

.steps-content{
    transition:step-start;
    transition-duration: 2s;
    scroll-behavior: smooth;
}

.no-overflow-x{
    /* overflow-x: hidden; */
}

.btn-onback{
    margin-top: -5px;
}
.select-after{
    min-width: 80px;
}
.border{
    border:1px solid #D9D9D9;
}


.ant-picker-content{
    width: inherit;
}


/* agente citas */
.card-agente-info{
    max-width: 400px;
}
.agente-row-info{
    margin-top: 1.5rem;
}
@media (max-width: 1080px){
    .ant-picker-content{
        width:inherit!important;
    }
}

/*----------------CELDAS CALENDARIO----------------*/

.calendario-small .badge-calendario{
    position: absolute;
    height: 5px;
    width: 5px;
    bottom: 5px;
    left: 42%;
    right: 0;
}

.calendario .ant-picker-calendar-date-content .citas{
    padding-left: 0.1rem;
}

.hover-badge{
    border-radius: 10px;
    padding-left: 0.2rem;
}
.hover-badge:hover{
    background: #cacaca;
}

.hover-badge:hover *{
    color: white;
}



.divider-element {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
}

.divider-element .ant-divider-inner-text{
    /* font-size: 1em !important; */
    /* margin-top: 1em; */
    font-weight: lighter;
    color: rgba(0,0,0,0.5);
}

.caracteristicas-container{
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 10px;
}
.header-imagenes{
    margin-bottom: 2em;
}

.form-imagenes{
    max-width: 1200px;
   
}

.grid {
  position: relative;
  width: 100%;

  border: 5px solid transparent;
}
.item {
  position: absolute;

  line-height: 100px;
  margin: 5px;
  z-index: 1;

  resize: both;
  overflow: auto;
  max-width: calc(100% - 10px);
  box-shadow: 0 .125rem .25rem rgba(0,0,0,0.15)!important;
  overflow: hidden !important;
}

.item.muuri-item-hidden {
  z-index: 0;
}

.item.muuri-item-releasing {
  z-index: 2;
}

.item.muuri-item-dragging {
  z-index: 3;
}

.item-content {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.item.muuri-item-dragging,
.item.muuri-item-releasing{
  z-index: 100;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}



.item-dragabble-handle *{
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 1);
  -webkit-filter: drop-shadow(0px 0px 0px rgba(255,255, 255, 1));
          filter: drop-shadow(0px 0px 0px rgba(255,255, 255, 1));
}

.button{

  background: rgba(64, 169, 255, 0.82);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);


  float: right;
  margin-right: 10px;
  color: white !important;
  position: relative;
  top: 0.5em;
  right: 0.5em;
}



/* button button-delete
button button-options
button button-defecto */

.button.button-delete{
  background: rgba(255, 64, 64, 0.75) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043)!important; 
}

.button.button-delete:hover{
  background: rgba(255, 64, 64, 1) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043)!important; 
}



.button.button-options{
  background: rgba(64, 169, 255, 0.75) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}

.button.button-options:hover{
  background: rgba(64, 169, 255, 1 ) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}

.button.button-defecto{
  background: rgba(64, 169, 255, 0.75) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}

.button.button.button-defecto.selected{
  max-height: 40px !important;
  max-width: 190.89px !important;
}

.button.button-defecto:hover{
  background: rgba(64, 169, 255, 1 ) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}


.buttons-main-container {
  width: calc(100% - 10px) !important;
}




.list-modal-action{
  border: none !important;
  margin-bottom: 1.5em;
}

.list-modal-action .list-modal-action-title{
  font-weight: 500;
  color: #666666 !important;
  margin-bottom: 1.5em;
}


.list-modal-action .list-modal-action-checkbox{
  margin-top: 1.5em;
}


.accept-button{
  width: 350px;
  border-radius: 100px;;
  background: #3333CC;
  border-radius: 100px;
  color: white;
  margin: 0px auto;
  display: block;
  /* font-size: 1.3em; */
}


.accept-button:hover{
  width: 350px;
  border-radius: 100px;;
  background: rgb(21, 21, 136);
  border-radius: 100px;
}

.background-card{
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden!important;
}
/* .button-content{
  position: relative;
  top: 10px;
  right: 10px;
} */




.icon-description{
    float: right !important;
}


.divider-title{
    /* margin-top: 1em !important; */
    margin-top: 2em !important;
    margin-bottom: 2em !important;
    color: rgba(0,0,0,0.4) !important;
}
/* ajustes */
.menu-ajustes{
    min-height: 800px;
    width: 100%;
}
.form-ajustes{
    min-height: 800px;
}


/* listas */
.component-list{
    padding: 10px 10px;
}
.component-list-item{
    padding: 0 0 11px 0 !important;
    border: none !important;
}
.card-list{
    width: 100%;
    border-radius: 10px;
    font-size: 0.8rem;
    margin: 0;
    border: none;
}
.card-list .ant-card-body{
    padding:10px
}

.card-list-btn{
    color: #000000;
}

.btn-nostyle,
.btn-nostyle:hover,
.btn-nostyle:focus{
        color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0);
    /* /* border-color: transparent; */
}

.content-mensajes{
    padding: 15px 20px 10px 10px;

}

.content-mensajes .tituloVista{
    font-weight: 500;
    font-size: 30px;
    padding: 15px 0px 5px 35px;
}


.content-mensajes .row-titulo-vista{
    margin: 0px 10px;
    margin-bottom: 20px;
}

.contenedor-conversaciones .row-conversaciones{
    overflow-y: auto;
    max-height: 67vh !important;
}
 
.contenedor-conversaciones .input-search{
    width:  calc( 100% - 45px )!important;
    margin: 10px 0px;
}

.contenedor-conversaciones .btn-addPlusMorado{
    background: #3333CC;
    border: 1px solid #3333CC;
    box-sizing: border-box;
    border-radius: 2px;
    width: 35px;
    padding: 3px;
    margin-left: 5px;
    margin-top: 10px;
}


.contenedor-conversaciones .card-row-conversacion{
    margin: 15px 0px;
}


.contenedor-conversaciones .card-row-conversacion .ant-card-body{
    padding: 0px 15px;
}

.contenedor-conversaciones .col-img-agente{
    margin-top: 12px;
}

.contenedor-conversaciones .txt-empresa,
.contenedor-conversaciones .txt-nombre,
.contenedor-conversaciones .txt-ultCnx{
    line-height: 20px;
    color: #262626;
}

.contenedor-conversaciones .txt-empresa{
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
}

.contenedor-conversaciones .txt-nombre{
    font-weight: 500;
    font-size: 9px;
    line-height: 5px;
}


.contenedor-conversaciones .txt-ultCnx{
    font-weight: normal;
    font-size: 10px;
    float: right;
    
}


.contenedor-conversaciones .txt-ultMsj{
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #8C8C8C;
}


.contenedor-conversaciones .btn-card-files{
    background: #B5CFE7;
    border: 1px solid #2A8BF2;
    box-sizing: border-box;
    border-radius: 5px;
    color: #2A8BF2;
    padding: 0px;
    margin: 0px;
    min-width: 90px;
    display: inline-block;
    float: left;
    margin-top: 10px;
    font-weight: 500;
}


.contenedor-conversaciones .btn-msj,
.contenedor-conversaciones .btn-cita{
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.12));
    border-radius: 5px;
    
    min-width: 80px;
    padding: 0px;
    margin: 0px;
    top: 0px;
    
    position: relative;
    float: right;
    text-align: right;
    display:inline-flex;
    line-height: 34px;
    margin-left: 5px;
    margin-bottom: 5px;
    padding: 0px 4px;
    margin-top: 10px;
}


.contenedor-conversaciones .btn-msj svg,
.contenedor-conversaciones .btn-cita svg{
    width: 40px;
    margin-left: -6px;
    margin-right: 20px;
}


.contenedor-conversacion > .ant-row { 
    min-height: 75vh;
    padding:20px !important;
}

.contenedor-conversacion .cnt-mensajes{
    height:calc(70vh - 100px) !important;
    overflow-y: auto;
} 

.contenedor-conversacion .cnt-opciones{
    height: 100px !important;
    bottom: 0;
    border-top: solid 1px #D9D9D9;
} 

.contenedor-detalle .row-detalle{
    padding: 30px 20px;
}


.contenedor-detalle .txt-det-nombre{
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #262626; 
    padding: 0px 10%;
    margin: 0px;
}

.contenedor-detalle .txt-bold{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: justify;
    color: #000000;
}


.contenedor-detalle .ant-col{
    margin-bottom: 30px;
}

.contenedor-detalle {
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    text-align: justify !important;
}
    

.txt-link{
    color: #006BFF;
}


.contenedor-conversaciones,
.contenedor-conversacion,
.contenedor-detalle{
    padding: 2px 10px;
}
 
.contenedor-conversacion > .ant-row,
.contenedor-detalle > .ant-row{
    border-radius: 10px;
}

 .mr-5px{
     margin-right: 5px !important;
 }

 /*--------------------------------------*/

         

.cnt-Mensajes-opciones{
    display: inline-flex;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    background: white;
    width: 100%;
    margin-bottom: 10px;
}
 

.cnt-Mensajes-opciones .ant-input{
    width:  auto !important;
    max-height: 100px !important;
    height: 70px !important;
    min-width: calc(100% - 120px);
    background: transparent;
    padding-top: 10px!important;
    border: solid 1px transparent !important;
}

.cnt-Mensajes-opciones .ant-input::-webkit-input-placeholder{
    color:#CCC;
}

.cnt-Mensajes-opciones .ant-input::placeholder{
    color:#CCC;
}

.cnt-msj-opciones{
    width: 121px;
    padding-top: 6px;
    float: right;
}

.cnt-msj-opciones .btnAtrach{
    width: 30px;
    height: 30px;
    padding: 0px;
    padding-top: 2px;
    background: rgba(255, 255, 255, 0.178);
    margin-left: 9px;
    border: none;
    display: inline-block;
    position: relative;
    top: -16px;
    
}

 
.cnt-msj-opciones .btnSend{
    width:  62px;
    height: 62px;
    background: rgba(255, 255, 255, 0.178);
    margin-left: 15px;
    border: none !important;
    padding: 0px !important;
    border-radius: 100px;
}


 /*--------------------------------------*/
.card-msj{
    width: 100%;
    display: inline-block    ;
    border: none !important;
}


.card-msj .ant-card-body, 
.cnt-msj, 
.card-msj .ant-comment-inner{
    border: none !important;
    padding: 0px !important
}

.owner .ant-comment-content-author  {
    justify-content: flex-end;
}

.owner .ant-comment-content-author-name  {
    order: 2;
}

.owner .ant-comment-content-detail {
    position: relative;
    right: 8px;
    text-align: end;
}
 
.dateMsj{
    font-size: 7.5pt !important;
}
 

.card-msj .divCntMsj{
    background: linear-gradient(90.54deg, #60A9F6 0%, #2A8BF2 100%);
    border-radius: 0px 10px 10px 10px;
    color: white;

    position: relative;
    display: block;
    width: auto !important;
    min-width: 200px;
    max-width: 95% !important;
    padding: 15px;
    float: left;
}


.card-msj .owner .divCntMsj{
    background: #FFFFFF;
    border: 1px solid rgba(112, 124, 151, 0.25);
    box-sizing: border-box;

    color: #555 !important;
    
    border-radius: 10px 10px 0px 10px;
    right: 0 !important;
    float:  right;
}
 

.card-msj .btnMenuChat{
    position: relative;
    margin: 10px;
    margin-bottom: 0px;
    border-radius: 100px;
    background: transparent;
    border-color: transparent;
    
}
 

.card-msj .owner .divCntMsj{
    right: 20px !important;
    margin-left: 20px;
}

.card-msj  .msjStatus{
    right: 0;
    position: absolute;
    margin-right: 5px;
    margin-top: 15px;
    font-size: 11pt;
    color: #AAA !important;
}


.card-msj a {
    color: white !important;
}

.card-msj .chatFile{
    font-size: 25pt !important;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.322);
}


.card-msj .owner a {
    color: #2A8BF2 !important;
 
}

.card-msj .owner .chatFile{
    background-color:  #60a9f67c   !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

