@import url('https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css');

.dot-map{
    border-radius: 100%;
    width: 20px;
    height: 20px;
    border: 4.5px solid white;

    -webkit-box-shadow: 0px 0px 7px 0px rgba(138,138,138,1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(138,138,138,1);
    box-shadow: 0px 0px 7px 0px rgba(138,138,138,1);
}

.color-venta{
    background: #88cfb6 !important;
}

.color-renta{
    background: #7878dd !important;
}

.color-vendida{
    background: #dfff19 !important;
}


.marker {
    /* -webkit-box-shadow: 0px 0px 9px 4px rgba(0,0,0,0.23); 
    box-shadow: 0px 0px 9px 4px rgba(0,0,0,0.23); */
    width: 50px;
    height: 50px;
}

.img-location{
    padding: 5%;
    margin-left: -5px !important;
}


.info-location {
    padding-left: 12px !important;
}

.info-location .card-title{
    font-size: 10pt !important;
}

.info-location .ant-typography{
    margin-bottom: 1px !important;
}



.dropdown-tipo .btn-tipo:hover  
{
     background: white;
     border: 0px solid transparent;
    box-shadow: none!important;
}


.dropdown-tipo .btn-tipo .svg-div  
{
    position: relative !important;
    width: 20px !important;
}

.dropdown-tipo .btn-tipo .svg-div .svg-check
{
    position: relative !important;
    background: transparent !important;
    margin-left: -12px !important;
    margin-right: 0px !important;

}