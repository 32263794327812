
html, body {
    scroll-behavior: smooth !important;
}

/*Botón limón*/
.btn-limon
{
    background: #E0EF4D;
    color:#000;
    border: none;
}

.btn-limon:hover,
.btn-limon:active,
.btn-limon:focus
{
    background: #dbe770;
    color:#000;
    border: none;
} 


/*Section 1 landing*/
.landing-header-img{
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    display:inline-block;
    height: 37vw;
    vertical-align:bottom;
    min-height: 250px;
}  

 .lnd-sec-1{
    
    height: auto;
    padding: 0px;
    margin: 0px;
    width: 100% !important;
    position: relative !important;
    display: inline-block  !important;
    height:  40vw !important;
    min-height: 260px !important;
    max-height: 450px !important;
    overflow: hidden;
    line-height: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom; 
    background-size: cover;
    color: black;
}  

.lnd-sec-1:before {
    content:'';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(196,196,196,0) 0%, rgba(3,3,3,0.6) 100%);
}

.lnd-sec-2{
    padding-bottom: 10rem;
}

.lnd-sec-4{
    height: auto;
    padding: 0px;
    margin: 0px;
    width: 100% !important;
    position: relative !important;
    display: inline-block  !important;
    height:  20vw !important;
    min-height: 200px !important;
    max-height: 220px !important;
    overflow: hidden;
    line-height: 50%;
}  


.landing-header-img-2{
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    display:inline-block;
    height: 20vw;
    vertical-align:bottom;
    min-height: 200px;
}


.lnd-sec-4 .col-search .iconGotoLanding{
    margin-right: 10px;
}

/*Cards landing*/
.col-landing-card .ant-card{
    min-height: 450px;
    box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    min-height: 150px;
}


/*Tabs landing*/

.col-landing-tabs .ant-tabs{
    min-width: 100%;
}

.col-landing-tabs .ant-tabs-nav-wrap{
    display: inline-block !important;
    position: relative !important;
    width: 100%;
}

.col-landing-tabs .ant-tabs-tab{
    width:calc(100% / 5);
    text-align: center;
    min-width: 150px;
}

.card-ciudad{
    padding: 0px;
}
.card-ciudad{
    background: transparent;
    padding:0px;
    margin:10px;
    border-radius: 10px;
    border-color: transparent transparent;
   
}

.card-ciudad .ant-card-body{
    padding: 0px;
}


.card-ciudad .col-ciudad-img{
        padding: 0px;
        border-radius: 10px;
        display: block;
        position: relative;
        overflow: hidden;
        height: 10% !important;
        min-height: 220px !important;
        max-height: 300px !important;
        
    }
    
    .card-ciudad .col-ciudad-img img{
        padding: 0px;
        border-radius: 10px;
        height: auto !important; 
        width: 100% !important;
        min-height: 300px !important;
 
        -webkit-filter: brightness(60%);
        filter: brightness(60%);
        
}


.card-ciudad .col-ciudad-info{
    background: transparent;
    position: relative;
    margin-top: -70px;
    color:#FFF;
    text-align: left;
    margin-left: 20px;
} 


.card-ciudad .col-ciudad-info h3{
    margin-bottom: 0px;
} 


/*Radio landing*/
.landing-content .itemRadio{
    background: #C8D645;
    padding: 2px 0px;
    border-radius: 5px;
}

.landing-content .itemRadio .ant-radio-button-wrapper{
    background: transparent;
    border-radius: 5px;
    border: none;
    color:#FFF;
    margin: 0px 2px;
    min-width: 80px;
}


.landing-content .itemRadio .ant-radio-button-wrapper-checked{
    background: #FFF;
    color:#000;
    margin: 0px 2px;
}

.landing-content .itemRadio .ant-radio-button-wrapper::before,
.landing-content .itemRadio .ant-radio-button-wrapper-checked::before{
    background: transparent;
}



.col-search{
    /* background-color: red; */
}

.col-search .landing-page-search-input{
    /* min-width: 250px; */
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 1px rgba(255, 255, 255, 0.25);

    border:  none !important;
}


.col-search .landing-page-search-input input{
    /* min-width: 250px; */
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    border-radius: 6px 0px 0px 6px;
}


.col-search .ant-input-search-button{
    border:  none !important;

}

.col-search .landing-page-search-input .ant-input-group-addon{
    border-radius: 0px 6px 6px 0px !important;
}
/*Search landing*/
/* .col-search{
    background: white;
    width: 50%;
    margin: auto;
    max-width: 500px;
    border-radius: 5px;
    min-height: 50px;
    vertical-align: middle;
}

.col-search .inputSearch{
    padding: 0px;
    float: left;
    width: calc(100% - 35px);
    min-width: 430px;
    border: none !important;
}


.col-search .inputSearch input{
    min-height: 50px !important;
    border: none;
}

.col-search .inputSearch input::placeholder{
    color:#9E9E9E;
}

.col-search .inputSearch .ant-input-group-addon{
    display: none;
    visibility: hidden;
}

.col-search .btnSearch {
    width: 35px;
    padding: 0px;
    margin: 0px;
    min-height: 50px;
    border: none;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 0px;
}

.col-search .iconSearchLanding{
    float: left;
} */

.link-todas-cuidades{
    text-align: right;
    margin-right: 25px;
    padding: 15px 0px;
}

.row-bar-sub-blue{
    display: inline-block;
    margin: auto;
    width: 100%;
    max-width: 1150px;    
}


/*Globales*/
.text-white{
    color:#FFF !important;
} 

.center{
    text-align: center;
}
.left{
    text-align: left;
}

.mt-5{
    margin-top: 2%;
}

.pd-3{
    padding: 3rem;
}

.mg-10{
    margin:10px !important;
    padding:10px !important;
}

.pb-30{
    padding-bottom:30px !important;
}

.pd-10{
    padding: 10px;
}

.mxh-25prs{
    height: 20%;
}



.mxw-345px{
    max-width: 345px;
}
.mxw-1300px{
    max-width: 1300px;
}

.mxw-1400px{
    max-width: 1400px;
}

.wd-100prs {
    width: 100%;
}

.wd-70prs {
    width: 70%;
}

.main-landing-row{

}

@media (min-width: 1200px) {
    .xl-align-left{
     text-align: left !important;
     margin-top: 30px;
    }
 
}