.header-imagenes{
    margin-bottom: 2em;
}

.form-imagenes{
    max-width: 1200px;
   
}

.grid {
  position: relative;
  width: 100%;

  border: 5px solid transparent;
}
.item {
  position: absolute;

  line-height: 100px;
  margin: 5px;
  z-index: 1;

  resize: both;
  overflow: auto;
  max-width: calc(100% - 10px);
  box-shadow: 0 .125rem .25rem rgba(0,0,0,0.15)!important;
  overflow: hidden !important;
}

.item.muuri-item-hidden {
  z-index: 0;
}

.item.muuri-item-releasing {
  z-index: 2;
}

.item.muuri-item-dragging {
  z-index: 3;
}

.item-content {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.item.muuri-item-dragging,
.item.muuri-item-releasing{
  z-index: 100;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}



.item-dragabble-handle *{
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 0px 0px rgba(255,255, 255, 1));
}

.button{

  background: rgba(64, 169, 255, 0.82);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);


  float: right;
  margin-right: 10px;
  color: white !important;
  position: relative;
  top: 0.5em;
  right: 0.5em;
}



/* button button-delete
button button-options
button button-defecto */

.button.button-delete{
  background: rgba(255, 64, 64, 0.75) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043)!important; 
}

.button.button-delete:hover{
  background: rgba(255, 64, 64, 1) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043)!important; 
}



.button.button-options{
  background: rgba(64, 169, 255, 0.75) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}

.button.button-options:hover{
  background: rgba(64, 169, 255, 1 ) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}

.button.button-defecto{
  background: rgba(64, 169, 255, 0.75) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}

.button.button.button-defecto.selected{
  max-height: 40px !important;
  max-width: 190.89px !important;
}

.button.button-defecto:hover{
  background: rgba(64, 169, 255, 1 ) !important; 
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
}


.buttons-main-container {
  width: calc(100% - 10px) !important;
}




.list-modal-action{
  border: none !important;
  margin-bottom: 1.5em;
}

.list-modal-action .list-modal-action-title{
  font-weight: 500;
  color: #666666 !important;
  margin-bottom: 1.5em;
}


.list-modal-action .list-modal-action-checkbox{
  margin-top: 1.5em;
}


.accept-button{
  width: 350px;
  border-radius: 100px;;
  background: #3333CC;
  border-radius: 100px;
  color: white;
  margin: 0px auto;
  display: block;
  /* font-size: 1.3em; */
}


.accept-button:hover{
  width: 350px;
  border-radius: 100px;;
  background: rgb(21, 21, 136);
  border-radius: 100px;
}

.background-card{
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden!important;
}
/* .button-content{
  position: relative;
  top: 10px;
  right: 10px;
} */



