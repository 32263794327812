.list-content .row-bg-image{
    height: auto;
    background-repeat: no-repeat !important;
    background-size: cover;
    text-align: center;
    background-position-y: 20%;
    position: relative;
    min-height: 20vw;
}

.row-bg-image h2{
    margin-top: 10% !important;
}

.cnt-center{
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 15px 0px;
}


.list-card-agente{
    border-radius: 5px;
    min-height: 50px;
    margin-bottom: 20px;
    /* box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.25); */
    border: none !important;
    width: 90%;
}


.list-card-agente .ant-card-body{
    padding: 0px;
}

.col-img-agente{
    width: 40%;
    padding-top: 10px;
}


.imgCircle{
    border-radius: 100px;
    overflow: hidden;
}

.cnt-center .text-blue{
    color:#006AFF;
}

.col-info-agente   .text-blue{
    margin-bottom: 0px !important;
    font-size: 1rem;
    margin-bottom: 0.2rem !important;
}

.col-info-agente-2   .text-blue{
    margin-bottom: 0px !important;
    font-size: 1rem;
    margin-bottom: 0.2rem !important;
}

.iconImgCircle{
    position: relative;
    bottom: 0;
    z-index: 1;
    margin-left: -40px;
    margin-bottom: -70px;
}


.col-info-agente{
    width: 60%;
    text-align: left;
    font-size: 0.65rem;
    padding: 5px 0px;
    padding-left: 5px;
}

.col-info-agente-2 {
    width: 60%;
    text-align: left;
    font-size: 0.65rem;
    padding: 5px 0px;
    padding-left: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.col-info-agente .ant-col {
    margin-bottom: 3px;
}

.col-card-descripcion .ant-typography{
    text-justify: auto !important;
}

.tabs-agentes{
    color:#3333CC;
}

.tabs-agentes .IconUbicacion{
    display: block;
    margin-right: 8px;
    margin-top: -28px;
    float: right;
    z-index: 10;
    position: relative;
}

.tabs-agentes .search-ubicacion .anticon-search{
    display: none  !important;
    visibility: hidden !important;
}

 .iconImgInsignia{
     float: right;
     height: auto;
     width: auto;
     max-width: 20%;
     min-width: 60px;
     display: block;
     position: absolute;
     right: 0;
     z-index: 1;

 }
 
 .row-iconimgInsignia{
    max-width: 90px !important;
 }

 .imgMapa{
     width: 98%;
 }


 .row-card-agente {
  width: 100%;
 }

 /*Testimonios*/
 .carusel-testimonios li {
    width:  11px !important;
    height: 11px !important;
}


 .carusel-testimonios button{
    background: #3333CC !important;
    border-radius: 100px !important;
    width:  7px !important;
    height: 7px !important;
}

 
.row-testimonio{
    width: 90% !important;
    margin: auto !important;
    max-width: 500px;
    padding: 5px 10px !important;
}

 .col-tst-fecha {
    text-align: right;
    padding:10px ;
}

 .col-tst-fecha .ant-typography{
     color: #006AFF;
    font-size: 12px;
    
 }


 .col-tst-autor,
 .col-tst-testimonio {
     text-align: left;
 }


.col-tst-testimonio  .ant-typography{
    font-style: italic;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.col-tst-autor .ant-typography{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}

.list-agentes  .ant-list-item {
    border-bottom-color: transparent !important;

}


/*Globales*/
.mg-auto{
    margin: auto;
}

.ml-50px{
    margin-left: 50px;
}

.pdr-10px{
    padding-right: 10px !important;
}

.pdl-10px{
    padding-left: 10px !important;
}

.mxw-1240px{
    max-width: 1240px;
}

.ml-50px{margin-left: 50px;}

.pd-0px{
    padding: 0px !important;
}
 
.pd-10px{
    padding: 10px !important;
}

.btn-gh-blue{
    color:#3333CC !important;
    border-color:  #3333CC !important;
}

.right{
    text-align: right;
}

.card-btn-goto{
    font-family:  sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3333CC;
    padding: 5px 15px 10px 15px;
}

.card-btn-goto svg{
    margin-left: 10px;
    top: 3px;
    position: relative;
}

.text-link-blue,
.text-link-blue:hover,
.text-link-blue:focus,
.text-link-blue:active{
    float: left;
    font-family:  sans-serif;
    font-style: normal;
    font-weight:600;
    font-size: 16px;
    line-height: 24px;
    color: #3333CC;
    margin-top: -17px;
    margin-left: 0px;
    width: 150px;
    text-align: left;
    padding-left: 30px;
    min-width: 300px;

}
 
.menuLista  {
    margin-bottom: 12px;
}


.menuLista .ant-menu-item  h4.ant-typography,
.menuLista  .ant-typography {
    box-sizing: border-box !important;
    align-items: left !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    color: #3333CC !important;
}


/*Vista individual*/
.col-contenedor-border{
    border: solid 1px #D3DEE8;
    width: calc(100% - 20px) !important;

    margin: 10px;
    padding: 10px;
}

.col-agente-img{
    text-align: left;
    max-width: 200px;
    float: left;
}

.col-agente-img .img-perfil{
    height: 200px;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover; 
    position: relative;
}

.spanRedesSociales{
    position: absolute;
    background:#3333CC ;
    display: block;
    float: left;
    z-index: 10;
    width: 75px;
    height: 30px;
    padding: 5px;
    bottom: 12px;
    left: 0px;
}

.col-agente-info{
    width: calc(100% - 200px);
}

.col-agente-info .col-info{
    margin-bottom: 0.5rem;
}

.col-agente-info .col-info .svg-item{
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}


.imgFotoAgente{
    width: 100%;
    height: auto;
}

.col-agente-info{
    text-align: left;
    padding-left: 10px;
}



.spanRedesSociales svg{
    margin: 0px 8px;
}

.btn-agenteFavorito{
    background: transparent;
    border-radius: 100px;
    width:  34px;
    height: 34px;
    padding: 0px;
    border-color: transparent;

    float: right;
    position: relative;
    display: block;
    right: 0px;
    top:-40px
}


.col-contenedor-border .list-card-agente{
    border-color: transparent !important;
    box-shadow: none !important;
    width: 100% !important;
    padding: 0px !important;
    
}

.col-contenedor-border .col-card-descripcion{
    text-align: left !important;
    text-justify: auto; 
    padding-left: 5px;
    margin-top: 10px;
}

.muesca-blue{
    width: 60px;
    height: 4px;
    background:#3333CC ;
    display: block;
    margin-bottom: 10px;
}

.mb-10px{
    margin-bottom: 10px;
}

.mt-50px{
    margin-top: 50px;
}

.pt-10px{
    padding-top: 10px;
}

.col-carusel-detAgente{
    padding: 10px;
}

.col-carusel-detAgente .carusel-testimonios{
    width: 100% !important;
    display: block;

}

.col-carusel-detAgente .row-testimonio{
    margin: auto !important;
    width: 100% !important;
    padding: 10px;
    margin-bottom: 15px !important;
    min-width: 100% !important;
    text-align: center;
}

.col-carusel-detAgente .col-info{
    display: block !important;
    width: 100% !important;
    margin-bottom: 50px !important;
    margin: auto !important;
    min-width: 100% !important;
    padding: 20px 20% !important;
}


.col-carusel-detAgente .col-txt-fecha,
.col-carusel-detAgente .col-txt-testimonio,
.col-carusel-detAgente .col-txt-autor{
    margin-bottom: 10px;
}


.col-carusel-detAgente .col-txt-fecha{
    text-align: right;
    color: #6100FF !important;
}


.col-carusel-detAgente .col-txt-fecha .ant-typography{
    color: #6100FF !important;
}

.col-carusel-detAgente .col-txt-testimonio{
    text-align: left;
    font-weight: bold;
    font-style: italic;
}

.col-carusel-detAgente .col-txt-autor{
    font-weight: normal !important;
    text-align: left;
}