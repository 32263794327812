.icon-description{
    float: right !important;
}


.divider-title{
    /* margin-top: 1em !important; */
    margin-top: 2em !important;
    margin-bottom: 2em !important;
    color: rgba(0,0,0,0.4) !important;
}