/*------------CSS PARA MODAL LOGIN--------------*/
.modal-bauens .ant-modal-body{
	/* padding-top: 2.5rem; */
	/* padding: 0;  */
	/* margin: 10px; */

	padding: 2.5em 0 2rem 0 ;
}

.modal-bauens .tabs-login .ant-tabs-nav{
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 2.5rem;
}

.modal-bauens .tabs-login .ant-tabs-nav-list{
	width: 100%;
	display: flex;
	justify-content: center;
}

.modal-bauens .tabs-login  .ant-tabs-tab{
	width: 40%;
	display: flex;
	justify-content: center;
	padding: 8px 0;
	color: #262626;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
}

.modal-bauens .tabs-login .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: var(--bauens-blue) !important;
}

.modal-bauens .tabs-login .ant-tabs-ink-bar{
	background-color: var(--bauens-blue) !important;
}

.modal-bauens .checkbox-login{
	font-size: 12px;
	margin: 1rem 0;
}


.modal-bauens .modal-login-form{
	margin: 0 24px 24px 24px
}

.modal-bauens .btn-login,
.modal-bauens .btn-login:hover,
.modal-bauens .btn-login:focus {
	width: 100%;
	background-color: #e0ef4e;
	border: none;
	border-radius: 25px;
	height: 35px;
	color: #272a0d;

}

.modal-bauens .btn-facebook-login,
.modal-bauens .btn-facebook-login:hover,
.modal-bauens .btn-facebook-login:focus {
	background-color: #006afe !important;
	height: 35px !important;
	margin: 1rem 0 !important;
	color: white !important;
	font-weight: 500 !important;
	border: none !important;
	width: 100% !important;
	border-radius: 35px !important;
	position: relative !important;
	cursor: pointer !important;
}

.modal-bauens .btn-google-login,
.modal-bauens .btn-google-login:hover,
.modal-bauens .btn-google-login:focus {
	height: 35px !important;
	font-weight: 500 !important;
	border: solid 1px #a29f9f !important;
	width: 100% !important;
	border-radius: 35px !important;
	position: relative !important;
	color: #a29f9f !important;
	text-align: center !important;
	padding: 0px 0px 0px 0px !important;
	line-height: 15px;
	
}
 

.modal-bauens .btn-google-login span{
	height: 35px !important;
	font-weight: 500 !important;
	width: 100% !important;
	position: relative !important;
	color: #a29f9f !important;
	text-align: center !important;
}


.btn-svg-login-f{
	height: 23px;
	width: 23px;
	position: absolute;
	left: 6px;
	top: 6px;

}

.btn-svg-login-g{
	height: 21px;
	width: 21px;
	position: absolute;
	left: 5px;
	top: 6px;
}

.modal-bauens .ant-form-item-label label,
.modal-bauens .text-label {
	font-weight: bold;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.85);
}

.modal-bauens .modal-title{
	font-weight: 500;
	font-size: 20px;
	color: #000000;
	margin-bottom: 1.5rem;
}

.modal-bauens .ant-form-item-control-input-content{
	display: flex;
	align-items: center;
}

.modal-bauens .text-password{
	margin-left: auto;
	margin-right: 0px;
	font-size: 11px;
	color:  gray;
}

.modal-bauens .text-password:hover{
	text-decoration: underline;
	cursor: pointer;
}

/*-------------------CSS DEL MODAL DE INMUEBLE------------------*/
.modal-inmueble{
	width: 95% !important;
	max-width: 1300px !important;
	top: 10px !important;
}

.modal-inmueble .ant-modal-body{
	padding: 0 0 0 0;
	max-height: 96vh;
	overflow: hidden;
}

.modal-inmueble .col-imagen{
	max-height: 96vh;
	overflow: scroll;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.modal-inmueble .col-imagen::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.modal-inmueble .content-modal-inmueble{
	max-height: 47vh;
	overflow-y: scroll;
	overflow-x: hidden; 
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.modal-inmueble .content-modal-inmueble::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


.modal-inmueble .menu-modal{
	font-size: 12px;
	width: 100%;
}

.modal-inmueble .menu-modal .ant-menu-item{
	margin: 0 15px 0 0;
} 

.modal-inmueble .text-sub{
	font-weight: 600;
} 

.modal-inmueble .text-cat{
	font-weight: 400;
	font-size: smaller;
} 
/*-----------------------------------CSS CARD PASSWORD---------------------------*/
.card-password {
	width: 100%;
	max-width: 450px;
	min-height: 300px;
	border-radius: 12px !important; 
	box-shadow: 0px 5px 10px #ddd;
}

/*---------------------------*/
.modal-contacto{
	width: 80% !important;
	max-width: 1000px !important;
}

.ant-popover-placement-top 
{
	display: none !important;
}

.modal-contacto .contenido-modal{
	padding: 10px 5%;
	width: 100%;
	position: relative;
	min-height: 400px !important;
	max-width: 1500px;
	margin: auto !important;
}


.modal-contacto .btn-closeModal{
	width: 20px;
	height: 20px;
	border: none !important;
}	


.modal-contacto .tituloModal {
	color: #666666 !important;
	text-align: center;
	margin: 0px;
	font-weight: normal;
}

.text-bold{
	font-weight: bold !important;
}

.grupoRadio-mdlCnt{
	width: 100%;
	text-align: center !important;
	position: relative;
	display: table;
}
 
.grupoRadio-mdlCnt .ant-radio-button-wrapper.radioButton{
	float: left;
	height: auto;
	text-align: center;
	border-radius: 10px;
	padding: 10px;
	min-height: 170px !important;
	background: white;


	display: block;
	margin: 10px;
	width: 92%;
	border: solid 2px #C8CAD0 !important
}


.grupoRadio-mdlCnt .radioButton::before{
	color: transparent !important;
	background: transparent !important;
}

.grupoRadio-mdlCnt .ant-radio-button-wrapper .col-img{
	padding-top: 25px;
	height: 100px;
	color: #C8CAD0;
}

.grupoRadio-mdlCnt  .ant-radio-button-wrapper .col-txt{
	padding: 0px 20px;
	height: auto;
}


.grupoRadio-mdlCnt .radioButton .ant-typography{
	font-family: sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	display: inline-block;
	line-height: 20px !important;
	color: #666666;
}



.grupoRadio-mdlCnt  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton,
.grupoRadio-mdlCnt  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton:hover,
.grupoRadio-mdlCnt  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton:focus
{
	background: linear-gradient(90deg, #3333CC 0%, #2424A4 102.04%);
	color: #FFFFFF !important;
	border-radius: 10px;
	border: solid 2px #2424A4 !important;
}


.grupoRadio-mdlCnt .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton .col-img,
.grupoRadio-mdlCnt .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radioButton .col-txt .ant-typography{
	color: white !important;	
}


.pd-30px{
	padding:20px;
}

.txt-gray{
	color: #A4A4A4 !important;
	 
}

.txt-gray.ant-divider-horizontal.ant-divider-with-text::before, 
.txt-gray.ant-divider-horizontal.ant-divider-with-text::after {
	top:0% !important;
	border-top: 1px solid #a4a4a4b6 !important;
}


.row-info {
	font-weight: 500;
}

.row-info .text-blue{
	color: #3333CC !important;
}

.btnArrowCircle{    
	display: block;
    position: relative;
	top: -85px;
    margin-left: 0px !important;
    margin-right: 0px !important;
	padding-left: 0px !important;
}
 

.btnArrowCircleList,
.btnArrowCircle{
	width:  26px;
	height: 26px;
	padding: 0px;
	margin: 0px;
	border-radius: 20px;
	border-color: transparent !important;
}

.btnArrowCircleList{
	width:  20px !important;
	height: 20px !important;
	padding: 0px  !important;
	margin: 0px !important;
}

.list-profesiones,
.wd-100prs {
    width: 100%;
}

.mt-30px{
	margin-top: 30px;
}

.mt-20px{
	margin-top: 20px !important;
}


/*-----------------------CSS Modal contacto---------------------------*/
.modal-contacto .ant-tabs-nav-wrap{
	display: none !important;
	visibility: hidden !important;
}
.frm-contacto label{
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.85);


}
.btn-mdl-guardar{
	color: #FFFFFF !important;
	border-radius: 5px !important;
	padding: 5px !important;
	background: #87D068 !important;
	font-size: 16px !important;
	min-width: 170px !important;
	height: 50px !important;
}


/*Step paquetes*/

.rdbtn-paquete{
	background: #FFFFFF !important;
	float: left;
	height: auto !important;
	/* width: 90% !important;
	margin: 5% !important; */
	text-align: center !important;
	padding: 0px !important;
	border-radius: 0px 0px 10px 10px !important;
	border-color: transparent !important;
	box-shadow: -2px -2px 8px  rgba(3, 68, 158, 0.301);
	border: none !important;
	min-height: 350px !important;
}


.rdbtn-paquete .col-nombre{
	background: #1890FF !important;
	font-family: Montserrat !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 50px !important;

	display: block !important;
	align-items: center !important;
	text-align: center !important;
	letter-spacing: 0.2px !important;
	color: #FFFFFF !important;
	margin-top: -2px !important;
	width: calc(100% + 2px) !important;
}

.rdbtn-paquete .col-precio{
	padding: 60px 5px 10px 5px !important;
}


.rdbtn-paquete .col-precio .ant-typography{
	color: #87D068 !important;
	font-family: Montserrat !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 22px !important;
	line-height: 50px !important;
	
	align-items: center !important;
	text-align: center !important;
	letter-spacing: 0.2px !important;

	display: inline-block !important;
	width: auto !important;
}

 
.rdbtn-paquete .col-precio span.ant-typography{
	font-size: 15px !important;
	margin-left: 5px !important;
}



.rdbtn-paquete .col-descripcion{
	font-family: Montserrat !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 18px !important;
	text-align: center !important;
	letter-spacing: 0.2px !important;
	color: #666666 !important;
	padding: 15px 10% !important;
}


.rdbtn-paquete .col-boton{
	padding: 20px;
}
 
.rdbtn-paquete .col-boton .btn-morado 
{
	background: #492AF9;
	border-radius: 5px;
	border-color:  transparent !important;
	color: #FFFFFF !important
}

.btn-mdl-finalizar,
.btn-mdl-finalizar:hover,
.btn-mdl-finalizar:focus,
.btn-mdl-finalizar:active{
	background: #E0EF4D !important;
	border-color:  #E0EF4D !important;
	border-radius: 5px;
	min-width: 150px !important;
	margin-top: 100px;
	color:#000;
}

.ant-radio-button-wrapper-checked.rdbtn-paquete{
	background: #188fff49  !important;
}

.rdbtn-paquete.ant-radio-button-wrapper:not(:first-child)::before {
	background: transparent !important;
}

.resultado-success .ant-result-title,
.resultado-success .ant-result-subtitle
{
font-family: Montserrat !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 18px !important;
line-height: 25px !important;
text-align: center !important;
color: #A4A4A4 !important;
}

@media (min-width: 900px) {
	.grupoRadio-mdlCnt .radioButton{
		width:46% !important;
		margin: 10px;
	}

	.rdbtn-paquete {
		width:40% !important;
		margin: 10px;
	
	}
}


@media (min-width: 1200px) {
	.rdbtn-paquete ,
	.grupoRadio-mdlCnt .radioButton{
		width: 30% !important;
		margin: 10px 1% !important;
		min-width: 220px;
	}

	.grupoRadio-mdlCnt {
		width: 100% !important;
		text-align: center !important;
		display: inline-block !important;
		text-align: center !important;
		padding-left: 8% !important;
	}
}
 