.bg-white{
    background: #fff;
}

.bg-gray{
    background: rgba(0, 0, 0, 0.02);
}

.bg-purple{
   background: rgba(51, 51, 204, 0.1);
}

.list-messages{
    min-height: 277px;
    max-height: 277px;
    width: 100%;
    height: 277px;
    overflow-y: auto;
}

.list-messages > div .ant-list-empty-text{
    height: 240px!important;
}

.shadow-sm{
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.list-item-property-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #262626;
}
.list-item-property-prospecto{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    color: #262626;
}

.property-actions .ant-comment-content-author,.property-actions .ant-comment-actions {
    justify-content: space-between;
}
.property-actions ul li{
    vertical-align: middle;
    display: inline-flex;
    justify-content: flex-end;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.tiny{
    font-size: 10px;
}
.btn-property-files{
    border-radius: 5px;
    background: #B5CFE7;
    border-color:#2A8BF2;
    float: left;
    
}
.btn-property-files span{
    color:#2A8BF2;
    font-weight: 600;
}
.btn-property-group {
    display: inline-flex;
}
.btn-property-action-medium{
    border-radius: 5px;
    min-width: 45px;
    min-height: 25px;
    height: auto;
    font-size: 0.70rem;
    padding: 0px 5px;
}
.btn-property-action-large{
    border-radius: 5px;
    min-width: 100px;
    min-height: 20px;
    max-width: 200px;
    max-height: 20px;
    
    font-size: 0.70rem;
    padding: 0px 5px;
}
.btn-property-action {
    border-radius: 5px;
    margin-left:3px;
    min-width: 52px;
    min-height: 20px;
    width: auto;
    font-size: 0.70rem;
    padding: 0px 5px;
}

.align-center{
   
    display: flex;
	align-items: center;
}
.align-middle{
    vertical-align: middle;
}
.btn-yellow{
    background: #E0EF4D;
    color: #000;
}

.btn-blue {
    background: #3333CC;
    color: #fff;
    border: none;
    margin-right: 0.5rem;
}

.btn-yellow{
    background: #E0EF4D;
    color: #000;
    border: none;

}

.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active{
    background: #3333CC;
    color: #fff;
    border: none;
}
.text-blue{
    color: #3333CC;
}

.input-search-addon-blue{
    max-width: 500px;
}
.input-search-addon-blue .ant-input-group-addon{
    background: transparent;
    display: none;
}
.input-search-addon-blue .ant-input-group-addon button{
    /* background: #3333CC; */
    background: transparent;
    color: #3333CC!important;
    border: none;
    box-shadow: none;

}

/*Section 1*/
.db-sec-1{
       background-position: center;
       background-size: cover;
       background-repeat: no-repeat;
       background-position: 50%;
       position: relative;
       height: auto !important;
       height: 50vw  !important;
       min-height: 450px !important;
       max-height: 650px !important;
       margin-bottom: -10px;
}  

.row-db-sec-1{
    width: 70%;
    margin: auto;
    padding: 5px;
    height: 250px;
    margin-top: 7%;
    max-width:1000px;
}

.row-db-sec-1 .ant-col{
    margin: 0px !important;
    padding:0px !important;
    height: auto !important; 
    margin-bottom: 1.5vw !important;
}


.row-db-sec-1 .ant-col h4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}


.row-db-sec-1  .btn-limon{
margin-top: 25px;
}


.btn-db-down{
    display: block;
    position: absolute !important;
    bottom: 10px !important;
    width: 65vw !important;
    max-width: 1000px;
}

.db-sec-2{
    padding: 100px 10px;
    width: 100%;
    margin:auto;
    max-width: 1200px;
}

.db-imgClientes{
    width: 90%;
    height: auto;
}

.pb-30px{
    padding-bottom: 30px !important;
}
/*-------------------*/

.db-sec-3{
    background-position: center;
       background-size: cover;
       background-repeat: no-repeat;
       background-position: 50%;
       position: relative;
       height: auto !important;
       height: 20vw  !important;
       min-height: 350px !important;
       max-height: 500px !important;
       margin-bottom: -5px !important;
}

.row-db-sec-3{
    width: 90%;
    margin: auto;
    padding: 5px;
    height: 200px;
    margin-top: 4%;
    max-width:1500px;
}

.row-db-sec-3 .ant-col{
    margin: 0px !important;
    padding:0px !important;
    height: auto !important; 
    margin-bottom:0px !important;
}

.row-db-sec-3 .ant-col h4,
.row-db-sec-4 .ant-col h4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 0px!important;
    margin-bottom: 15px !important;
}

.row-db-sec-3 .ant-col h2{
    margin-bottom: 50px;
}

.row-db-sec-3 .text-blue{color : #3333CC!important}
.row-db-sec-3 .text-red {color : #EB4335 !important}


.db-imgLogoKey{
    width: 70px;
}

.db-sec-4{
    margin:60px 0px 10px 0px;
    padding:10px !important;
    display: block !important;
    position: relative;
    min-height: 450px !important;
}

.row-db-sec-4{
    width: 100%;
    max-width: 1000px;
    margin: auto !important;    
    min-height: 400px !important;
    height: auto !important;
    
}

.col-carusel{
    margin-bottom: 10PX;
}

.row-db-sec-4 .ant-carousel{
    min-height: 250px;
    overflow: hidden;
    position: relative;
    display: block;
    padding: 10px;
}

.col-img-cliente{
    width:  100px;
    height: 100px;
    border-radius: 100px;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center !important;
}
 

.row-cliente{
    width: 100%;
    display: inline-block;
    padding:10px;
}



.row-cliente .col-img,
.row-cliente .col-info{
    float: left;
    margin: auto !important;
    width: 100%;
}

.row-cliente .col-img{
    margin-bottom: 15px !important;
    margin-top: 5px;
}
.row-cliente .col-info{
    padding-left: 5px;
}

.row-cliente .iconLogoCircle{
    
    display: block;
    z-index: 1;
    margin-top: 60px;
    margin-right: -3px;

}

.row-cliente .col-txt-comentario{
    font-style: italic !important;
    font-size: 15px;
    
}
.card-tipo-text{
    font-weight: 700;
    font-size: 0.8rem;
    flex-wrap: wrap!important;
    word-wrap: break-word!important;
}
.shadow-card{
    background: #FFFFFF;
    border: 1px solid #D3DEE8;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(0, 115, 225, 0.06);
    border-radius: 4px;

}
.card-property-type{ 
    max-width: 350px;
}
.card-property-type-image{
    height: 100px;
    width: auto;
    border-radius: 50px;

}
.row-cliente .col-txt-cliente h5{
 
    font-family: Montserrat;

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #2A2C30 !important;
}

.row-cliente .col-txt-puesto{
    font-size: 12px !important;
}


.regular-subtitle{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px!important;
    line-height: 66px;
    color: #000000;


}

.card-title .ant-card-head-title{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    /* identical to box height, or 264% */
    color: #000000;


}

.steps-content{
    transition:step-start;
    transition-duration: 2s;
    scroll-behavior: smooth;
}

.no-overflow-x{
    /* overflow-x: hidden; */
}

.btn-onback{
    margin-top: -5px;
}
.select-after{
    min-width: 80px;
}
.border{
    border:1px solid #D9D9D9;
}


.ant-picker-content{
    width: inherit;
}


/* agente citas */
.card-agente-info{
    max-width: 400px;
}
.agente-row-info{
    margin-top: 1.5rem;
}
@media (max-width: 1080px){
    .ant-picker-content{
        width:inherit!important;
    }
}

/*----------------CELDAS CALENDARIO----------------*/

.calendario-small .badge-calendario{
    position: absolute;
    height: 5px;
    width: 5px;
    bottom: 5px;
    left: 42%;
    right: 0;
}

.calendario .ant-picker-calendar-date-content .citas{
    padding-left: 0.1rem;
}

.hover-badge{
    border-radius: 10px;
    padding-left: 0.2rem;
}
.hover-badge:hover{
    background: #cacaca;
}

.hover-badge:hover *{
    color: white;
}
