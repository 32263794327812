@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

body{
    font-family: 'Montserrat', sans-serif !important;

}

:root{
	--bauens-blue: #3333cc;
}


.container,
.layout{
	min-height: 100vh !important;
}

.container-pro{
	padding: 1rem 2rem;
	max-width: 1400px;
	margin: 0 auto;

}

.back-white{
	background-color: white;
}

.btn-primary{
	min-width: 100px;
	margin: 0 0.5rem;
	background-color: var(--bauens-blue) !important;
	color: white !important;
	border-radius: 3px !important;
	border: none !important;
}

.btn-secondary{
	min-width: 100px;
	margin: 0 0.5rem;
	color: var(--bauens-blue) !important;
	border-radius: 3px !important;
	border: solid 1px var(--bauens-blue) !important;
	display:flex !important;
	justify-content: center;
	align-items: center;
}


.btn-close{
	min-width: 100px;
	margin: 0 0.5rem;
	color: #ff4d4f !important;
	border-radius: 3px !important;
	border: solid 1px var(#ff4d4f) !important;
	display:flex !important;
	justify-content: center;
	align-items: center;
}

.btn-secondary svg{
	height: 16px;
	width: 16px !important;
	margin-left: 6px;
}

.input-search .ant-btn{
	border-left: none !important;
	color: var(--bauens-blue) !important; 
	background: white !important;
	border-color: #d9d9d9;
}
.input-search .ant-btn:hover{
	border-color: #d9d9d9;
}
 
.hr, hr{
	border: none;
	margin: 0.8rem 0;
	border-top: solid 1px #ddd !important;
} 

.hr-strong{
	border: none;
	margin: 0.8rem 0;
	border-top: solid 2px #b9b8b8 !important;
} 

@media(max-width: 766px){
	.container-pro{
		padding: 2rem 1rem;
	}
}

/*---------------CLASES GLOBALES----------------*/
.borderr{
	border: solid 1px red;
}
.borderless{
	border: none;
}

.center{
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.flex{
	display: flex !important;
}

.float-right{
	float: right;
}
.float-left{
	float: left;
}
.flex-column{
	display: flex !important;
	flex-direction: column;
}

.flex-end{
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}
.flex-end-top{
	display: flex !important;
	justify-content: flex-end;
	align-items: flex-start;
}
.flex-start{
	display: flex!important;
	justify-content: start!important;
	align-items: center!important;
}

.flex-base{
	display: flex !important;
	align-items: baseline;
	justify-content: space-between;
}

.p-1{
	padding: 1rem;
}
.p-2{
	padding: 2rem;
}
.p-3{
	padding: 3rem;
}

.pt-05{
	padding-top: 0.5rem;
}
.pt-1{
	padding-top: 1rem;
}
.pt-2{
	padding-top: 2rem;
}
.pt-3{
	padding-top: 3rem;
}
.pt-4{
	padding-top: 4rem;
}
.pt-5{
	padding-top: 5rem;
}


.pl-1{
	padding-left: 1rem;
}
.pl-2{
	padding-left: 2rem;
}
.pl-3{
	padding-left: 3rem;
}
.mg-1{
	margin:1rem !important;
}

.pb-1{
	padding-bottom: 1rem;
}
.pb-2{
	padding-bottom: 2rem;
}
.pb-3{
	padding-bottom: 3rem;
}

.pr-1{
	padding-right: 1rem;
}
.pr-2{
	padding-right: 2rem;
}
.pr-3{
	padding-right: 3rem;
}

.mt-1{
	margin-top: 1rem !important;
}
.mt-2{
	margin-top: 2rem !important;
}
.mt-3{
	margin-top: 3rem !important;
}
.mt-4{
	margin-top: 4rem !important;
}
.mb-1{
	margin-bottom: 1rem !important;
}
.mb-2{
	margin-bottom: 2rem !important;
}
.mb-3{
	margin-bottom: 3rem !important;
}
.mb-4{
	margin-bottom: 4rem !important;
}
.ml-1{
	margin-left: 1rem !important;
}
.ml-2{
	margin-left: 2rem !important;
}
.ml-3{
	margin-left: 3rem !important;
}
.ml-4{
	margin-left: 4rem !important;
}
.mr-1{
	margin-right: 1rem !important;
}
.mr-2{
	margin-right: 2rem !important;
}
.mr-3{
	margin-right: 3rem !important;
}
.mr-4{
	margin-right: 4rem !important;
}


.w-100{
	width: 100%;
}

.nolist-style{
	list-style: none;
}


.row-titleProspectos {
	padding: 5px 10px;
}
	

.row-titleProspectos h1{
	font-size: 13pt !important;
}