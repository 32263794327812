.row-filtros{
	background-color: white;
	min-height: 50px;
	padding: 1rem 2rem;
	border-bottom: solid 1px #ddd;
	margin-left: 0px !important;
	margin-right: 0px !important; 
}

.row-filtros .input-select,
.row-filtros .input-number{
	width: 100%;
}

.col-header{
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.select-med{
	width: 180px;
}



.btn-emailNotification{
	margin-top: 50px;
	background-color:  rgb(135, 208, 104);
	color: #FFF;
	border-radius: 5px;
	min-width: 480px !important;
	max-width: 600px !important;
	min-height: 115px !important;
	display: inline-block;
	position: relative;
	text-align: center;
	font-size: 11pt;
	padding: 20px;
}
 

.infinite-scroll-component__outerdiv{
	width: 100%  !important;
}

.infinite-scroll-component {
	overflow-x: hidden !important;
}