

.divider-element {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
}

.divider-element .ant-divider-inner-text{
    /* font-size: 1em !important; */
    /* margin-top: 1em; */
    font-weight: lighter;
    color: rgba(0,0,0,0.5);
}

.caracteristicas-container{
    position: sticky !important;
    top: 10px;
}