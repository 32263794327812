.content-mensajes{
    padding: 15px 20px 10px 10px;

}

.content-mensajes .tituloVista{
    font-weight: 500;
    font-size: 30px;
    padding: 15px 0px 5px 35px;
}


.content-mensajes .row-titulo-vista{
    margin: 0px 10px;
    margin-bottom: 20px;
}

.contenedor-conversaciones .row-conversaciones{
    overflow-y: auto;
    max-height: 67vh !important;
}
 
.contenedor-conversaciones .input-search{
    width:  calc( 100% - 45px )!important;
    margin: 10px 0px;
}

.contenedor-conversaciones .btn-addPlusMorado{
    background: #3333CC;
    border: 1px solid #3333CC;
    box-sizing: border-box;
    border-radius: 2px;
    width: 35px;
    padding: 3px;
    margin-left: 5px;
    margin-top: 10px;
}


.contenedor-conversaciones .card-row-conversacion{
    margin: 15px 0px;
}


.contenedor-conversaciones .card-row-conversacion .ant-card-body{
    padding: 0px 15px;
}

.contenedor-conversaciones .col-img-agente{
    margin-top: 12px;
}

.contenedor-conversaciones .txt-empresa,
.contenedor-conversaciones .txt-nombre,
.contenedor-conversaciones .txt-ultCnx{
    line-height: 20px;
    color: #262626;
}

.contenedor-conversaciones .txt-empresa{
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
}

.contenedor-conversaciones .txt-nombre{
    font-weight: 500;
    font-size: 9px;
    line-height: 5px;
}


.contenedor-conversaciones .txt-ultCnx{
    font-weight: normal;
    font-size: 10px;
    float: right;
    
}


.contenedor-conversaciones .txt-ultMsj{
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #8C8C8C;
}


.contenedor-conversaciones .btn-card-files{
    background: #B5CFE7;
    border: 1px solid #2A8BF2;
    box-sizing: border-box;
    border-radius: 5px;
    color: #2A8BF2;
    padding: 0px;
    margin: 0px;
    min-width: 90px;
    display: inline-block;
    float: left;
    margin-top: 10px;
    font-weight: 500;
}


.contenedor-conversaciones .btn-msj,
.contenedor-conversaciones .btn-cita{
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.12));
    border-radius: 5px;
    
    min-width: 80px;
    padding: 0px;
    margin: 0px;
    top: 0px;
    
    position: relative;
    float: right;
    text-align: right;
    display:inline-flex;
    line-height: 34px;
    margin-left: 5px;
    margin-bottom: 5px;
    padding: 0px 4px;
    margin-top: 10px;
}


.contenedor-conversaciones .btn-msj svg,
.contenedor-conversaciones .btn-cita svg{
    width: 40px;
    margin-left: -6px;
    margin-right: 20px;
}


.contenedor-conversacion > .ant-row { 
    min-height: 75vh;
    padding:20px !important;
}

.contenedor-conversacion .cnt-mensajes{
    height:calc(70vh - 100px) !important;
    overflow-y: auto;
} 

.contenedor-conversacion .cnt-opciones{
    height: 100px !important;
    bottom: 0;
    border-top: solid 1px #D9D9D9;
} 

.contenedor-detalle .row-detalle{
    padding: 30px 20px;
}


.contenedor-detalle .txt-det-nombre{
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #262626; 
    padding: 0px 10%;
    margin: 0px;
}

.contenedor-detalle .txt-bold{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: justify;
    color: #000000;
}


.contenedor-detalle .ant-col{
    margin-bottom: 30px;
}

.contenedor-detalle {
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    text-align: justify !important;
}
    

.txt-link{
    color: #006BFF;
}


.contenedor-conversaciones,
.contenedor-conversacion,
.contenedor-detalle{
    padding: 2px 10px;
}
 
.contenedor-conversacion > .ant-row,
.contenedor-detalle > .ant-row{
    border-radius: 10px;
}

 .mr-5px{
     margin-right: 5px !important;
 }

 /*--------------------------------------*/

         

.cnt-Mensajes-opciones{
    display: inline-flex;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    background: white;
    width: 100%;
    margin-bottom: 10px;
}
 

.cnt-Mensajes-opciones .ant-input{
    width:  auto !important;
    max-height: 100px !important;
    height: 70px !important;
    min-width: calc(100% - 120px);
    background: transparent;
    padding-top: 10px!important;
    border: solid 1px transparent !important;
}

.cnt-Mensajes-opciones .ant-input::placeholder{
    color:#CCC;
}

.cnt-msj-opciones{
    width: 121px;
    padding-top: 6px;
    float: right;
}

.cnt-msj-opciones .btnAtrach{
    width: 30px;
    height: 30px;
    padding: 0px;
    padding-top: 2px;
    background: rgba(255, 255, 255, 0.178);
    margin-left: 9px;
    border: none;
    display: inline-block;
    position: relative;
    top: -16px;
    
}

 
.cnt-msj-opciones .btnSend{
    width:  62px;
    height: 62px;
    background: rgba(255, 255, 255, 0.178);
    margin-left: 15px;
    border: none !important;
    padding: 0px !important;
    border-radius: 100px;
}


 /*--------------------------------------*/
.card-msj{
    width: 100%;
    display: inline-block    ;
    border: none !important;
}


.card-msj .ant-card-body, 
.cnt-msj, 
.card-msj .ant-comment-inner{
    border: none !important;
    padding: 0px !important
}

.owner .ant-comment-content-author  {
    justify-content: flex-end;
}

.owner .ant-comment-content-author-name  {
    order: 2;
}

.owner .ant-comment-content-detail {
    position: relative;
    right: 8px;
    text-align: end;
}
 
.dateMsj{
    font-size: 7.5pt !important;
}
 

.card-msj .divCntMsj{
    background: linear-gradient(90.54deg, #60A9F6 0%, #2A8BF2 100%);
    border-radius: 0px 10px 10px 10px;
    color: white;

    position: relative;
    display: block;
    width: auto !important;
    min-width: 200px;
    max-width: 95% !important;
    padding: 15px;
    float: left;
}


.card-msj .owner .divCntMsj{
    background: #FFFFFF;
    border: 1px solid rgba(112, 124, 151, 0.25);
    box-sizing: border-box;

    color: #555 !important;
    
    border-radius: 10px 10px 0px 10px;
    right: 0 !important;
    float:  right;
}
 

.card-msj .btnMenuChat{
    position: relative;
    margin: 10px;
    margin-bottom: 0px;
    border-radius: 100px;
    background: transparent;
    border-color: transparent;
    
}
 

.card-msj .owner .divCntMsj{
    right: 20px !important;
    margin-left: 20px;
}

.card-msj  .msjStatus{
    right: 0;
    position: absolute;
    margin-right: 5px;
    margin-top: 15px;
    font-size: 11pt;
    color: #AAA !important;
}


.card-msj a {
    color: white !important;
}

.card-msj .chatFile{
    font-size: 25pt !important;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.322);
}


.card-msj .owner a {
    color: #2A8BF2 !important;
 
}

.card-msj .owner .chatFile{
    background-color:  #60a9f67c   !important;
}