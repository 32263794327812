.dropdown-menu{
	padding: 0.5rem 1rem 1rem 1rem;
	max-width: 400px;
	min-width: 300px;
	max-height: 600px;
	width: 100%;
	overflow-y: auto; 
}

.dropdown-menu .flex-column{
	padding-bottom: 1rem;
}

.dropdown-menu .ant-typography.dropdown-title{
	font-size: 14px;
	margin-bottom: 1rem;
	font-weight: 700 !important;
	color:#000 !important;
}


.dropdown-menu .dropdown-check{
	font-size: 12px;
	margin-top: 0.4rem;
}


.dropdown-radio{
	width: 100%;
}

.dropdown-menu .dropdown-radio .ant-radio-button-wrapper{
	padding: 0 8px;
	font-size: 13px;
	min-width: 35px;
	text-align: center;
}


/*radio buttons de colores*/

.dropdown-collapse .radio-green .ant-radio-checked .ant-radio-inner,
.dropdown-collapse .radio-green .ant-radio-inner,

.simple-radio-green .ant-radio-checked .ant-radio-inner,
.simple-collapse .radio-green .ant-radio-inner 
{
	border-color: #87d068;
	border-width: 3px;
}

.dropdown-collapse .radio-green .ant-radio-inner::after,
.simple-radio-green .ant-radio-inner::after
{
	background-color: #87d068;
}

.simple-radio-green .ant-radio-inner::after{
	display: none;
}






.dropdown-collapse .radio-purple .ant-radio-checked .ant-radio-inner,
.dropdown-collapse .radio-purple .ant-radio-inner,

.simple-radio-purple .ant-radio-checked .ant-radio-inner,
.simple-collapse .radio-purple .ant-radio-inner {
	border-color: #7878dd;
	border-width: 3px;
}

.simple-radio-purple .ant-radio-inner::after,
.dropdown-collapse .radio-purple .ant-radio-inner::after{
	background-color: #7878dd;
}


.simple-radio-purple .ant-radio-inner::after{
    /* position: relative;
    left: 1px;
    top: 0.8px; */
	display: none;

}





.dropdown-collapse .radio-yellow .ant-radio-checked .ant-radio-inner,
.dropdown-collapse .radio-yellow .ant-radio-inner,

.simple-radio-yellow .ant-radio-checked .ant-radio-inner,
.simple-collapse .radio-yellow .ant-radio-inner {
	border-color: #dfff19;
	border-width: 3px;
}
.dropdown-collapse .radio-yellow .ant-radio-inner::after{
	background-color: #dfff19;
}

.simple-radio-yellow .ant-radio-inner::after{
    /* position: relative;
    left: 1px;
    top: 0.8px; */
	display: none;

}

.dropdown-collapse  .ant-radio-inner::after{
	width: 13px;
	height: 14px;
	top: -1px;
	left: -1px;
}


/*colapasable*/

.dropdown-collapse{
	width: 400px;
	padding: 0px;
}

.dropdown-collapse .ant-checkbox-group,
.dropdown-menu .ant-checkbox-group{
	width: 100%;
	margin-left: 1rem;
	margin-top: 0.5rem;
}

.dropdown-collapse .ant-checkbox-group-item,
.dropdown-menu .ant-checkbox-group-item,
.col-inmueble .ant-checkbox-group-item{
	margin: 0 !important;
	font-size: 12px;
}

.dropdown-collapse .ant-checkbox-wrapper,
.dropdown-menu .ant-checkbox-wrapper,
.col-inmueble .ant-checkbox-wrapper{
	width: 50%;
}


/*DROPDOWN DE TIPOS*/
/*3333CC*/
.dropdown-tipo{
	max-width: 250px;
	padding: 0.5rem;
}

.dropdown-tipo .btn-tipo{
	height: 100%;
	width: 100%;
	display:flex;
	align-items: center;
	/* border: 2px solid transparent; */
	width: auto;
	padding: 5px;
}

.dropdown-tipo .btn-tipo:hover{
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

	/* border: 2px solid red; */
}

.dropdown-tipo .btn-tipo .svg-div{
	position: relative;
}

.dropdown-tipo .btn-tipo .svg-div svg{
	height: 65px;
}

.dropdown-tipo .btn-tipo:hover svg {
	fill: #7878ce !important;
}

.dropdown-tipo .btn-tipo .svg-div.active svg{
	fill: #3333cc;
}

.dropdown-tipo .btn-tipo .svg-div .svg-check{
	display: none;
}

.dropdown-tipo .btn-tipo .svg-div .svg-check.active{
	position: absolute;
	left: 65%;
	top: 60%;
	display: block;
	height: 20px;
}

.dropdown-tipo .btn-tipo .div-text{
	padding-left: 1rem; 
	text-align: left;
	word-wrap: break-word;
}





/**  ESTILOS EXTRAS ***/
.dropdown-menu.extras{

	width: 100%;
	min-width: 420px;

}

.dropdown-menu.extras .extra-title-section{
	/* font-weight: bold; */
	margin-top: 5px;
}

.dropdown-menu.extras .extra-slider{
	
	margin-top: 1em;
	/* margin-bottom: 12px; */
}
.dropdown-menu.extras .input-search 
{
	margin-bottom: 2em;
}

.dropdown-menu.extras .checkbox-extra span{
	font-size: 12px !important;
}

.dropdown-menu.extras .checkbox-extra{
	margin-bottom: 3px;
}

.dropdown-menu.extras span.quantity-extra{

	font-weight: 300;
	font-size: 10px !important;
}

.dropdown-menu.extras span.quantity-extra svg{
	position: relative !important;
	top:  0;
	bottom: -20px !important;
}


.tag-extra{
	display: unset;
	position: relative;
	left: 5;
	padding: 0px 3px 0px 4px;
	border: none !important;
	background-color: unset !important;
	font-weight: 300;
	/* border: 1px solid rgba(0,0,0,.060); */
}

.input-extra-option input{
	font-size: 12px;
}

.disabled-tabpane {

	cursor: pointer !important;	
}
/* .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow */
/* .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow { */
    /* color: rgba(0, 0, 0, 0.25); */
    /* cursor: not-allowed; */
/* } */