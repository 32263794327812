.card-hoverable:hover{
	cursor: pointer;
	box-shadow: 0px 5px 10px #bbb;
}	

.card-inmueble{
	border-radius: 0px;
	box-shadow: 0px 5px 10px #ddd;
	border: none;
	transition: box-shadow 0.4s ease-in-out;
	height: 100%;
	overflow: hidden;
}

.card-inmueble .ant-card-body{
	padding: 0;
	background-color: transparent;
	display: flex;
	flex-flow: column;
	height: 100%;
}

.card-inmueble .card-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 230px;
  position: relative;
}

.card-inmueble .card-tag{
	position: absolute;
	top: 195px;
	left: 2%;
	font-size: 16px;
	font-weight: 300;
	border-radius: 5px;
	padding: 0.1rem 0.5rem;
}

.card-inmueble .card-body{
	min-height: 240px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	flex: 1 1 auto;
}


.card-inmueble .card-title{
	font-weight: 300;
}

.card-inmueble .card-text{
	font-size: 12px;
	margin:0 0 ;
	text-align: justify;
	height: 95px !important;
	-webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.card-inmueble .card-footer {
	margin-top: 1rem;
}

.card-inmueble .card-footer span{
	display: inline-block;
	width: 25%;
	text-align: center;
}

@media(max-width:	460px ){

	.card-inmueble .card-body{
		height: 300px !important;
	}

	.card-inmueble .card-footer .card-info{
		display: flex;
	}
	.card-inmueble .card-footer .card-info svg{
		display: none;
	}
}

@media(max-width: 1200px){
	.card-inmueble .card-body{
		height: 200px;
	}
}

@media(min-width: 1201px) and (max-width: 1420px){
	.card-inmueble .card-body{
		min-height: 300px;
	}
}

.card-inmueble .card-footer .card-direction{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	text-align: left;
}

.card-inmueble .card-footer svg{
	margin-right: 5px;
	height: 16px;
}

.card-inmueble .card-footer div.bl{
	border-left: solid 1px #ddd;
}

.card-inmueble .card-footer .card-info {
	display: flex;
	font-size: 12px;
}

.card-inmueble .card-footer .card-info  .ant-skeleton.ant-skeleton-element.ant-skeleton-active{
	width: 100%;
}

.card-inmueble .card-footer .card-info div{
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}


/*CARD-INMUEBLE SMALL*/


.card-inmueble-small{
	border-radius: 10px;
	box-shadow: 0px 5px 15px #ddd;
	border: none;
	margin-bottom: 1rem;
	overflow: hidden;
}

.card-inmueble-small .ant-card-body{
	padding: 0;
	background-color: transparent;
}

.card-inmueble-small .card-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  position: relative;
}

.card-inmueble-small .card-tag{
	position: absolute;
	top:85%;
	left: 2%;
	font-size: 16px;
	font-weight: 300;
	border-radius: 5px;
	padding: 0.1rem 0.5rem;
}

.card-inmueble-small .card-body{
	height: 230px;
	padding: 1rem;
	position: relative;
}

.card-inmueble-small .card-title{
	font-weight: 300;
	font-size: 19px;
}

.card-inmueble-small .card-text{
	font-size: 12px;
	margin:0 0 ;
	text-align: justify;
	height: 95px !important;
	overflow: hidden;
	text-overflow: ellipsis;

}

.card-inmueble-small .card-footer {
	position: absolute;
	bottom: 10px;
	right: 10px;
	left: 10px;
}

.card-inmueble-small .card-footer span{
	display: inline-block;
	width: 50%;
	text-align: center;
}

.card-inmueble-small .card-footer .card-direction{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.card-inmueble-small .card-footer svg{
	margin-right: 5px;
	height: 12px;
}

.card-inmueble-small .card-footer div.bl{
	border-left: solid 1px #ddd;
}

.card-inmueble-small .card-footer .card-info {
	display: flex;
	font-size: 10px;
}

.card-inmueble-small .card-footer .card-info div{
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/*--------------------------CARD INMUBLE PRO - ( Y BIG)-------------------------------------*/
/*------------------------------------------------------------------------------------------*/

.card-inmueble-pro,
.card-inmueble-pro-big{
	border-radius: 10px;
	box-shadow: 0px 5px 10px #ddd;
	border: none;
	transition: box-shadow 0.4s ease-in-out;
	overflow: hidden;
}

.card-inmueble-pro .ant-card-body,
.card-inmueble-pro-big .ant-card-body {
	padding: 0;
	background-color: transparent;
}

.card-inmueble-pro .card-image,
.card-inmueble-pro-big .card-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 250px;
  height: 100%;
  position: relative;
}

.card-inmueble-pro .card-image:before {
	content:'';
	position: absolute;
    top: 25%;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(180deg, rgba(196,196,196,0) 0%, rgba(3,3,3,0.89) 100%);
}

.card-inmueble-pro .card-title{
	font-size: 20px;
	color: white;
	font-weight: 500;
}

.card-inmueble-pro-big .card-title{
	font-weight: 500;
	font-size: 20px;
	color: #000000;
}


.card-inmueble-pro .card-direction{

	font-size: 13px;
	color: white;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.card-inmueble-pro-big .card-direction{
	font-weight: normal;
	font-size: 16px;
	color: #9D9D9D;
	display: flex;
	margin-bottom: 10px;
}

.card-inmueble-pro .card-direction svg{
	fill: white;
	height: 16px;
	margin-right: 8px;
}

.card-inmueble-pro .card-direction *{
	fill: white;
}

.card-inmueble-pro .position-down{
	position: absolute;
	bottom: 10px;
	left: 10px;
	right: 10px;
}

.card-inmueble-pro-big .card-direction svg{
	fill: #9D9D9D !important;
	height: 16px;
	margin-right: 8px;
}

.card-inmueble-pro-big .card-direction *{
	fill: #9D9D9D !important;
}

.card-inmueble-pro-big .card-text{
	text-align: justify;
	font-weight: normal;
	font-size: 12px;
	color: #000000;
	min-height: 90px;
}

.card-inmueble-pro-big .card-price{
	font-weight: 600;
	font-size: 24px;
	color: #000000;
	margin: 0;
}

.card-inmueble-pro-big .card-price small{
	font-weight: 400;
	font-size: 22px;
	color: #000000;
	margin: 0;
}

.card-inmueble-pro .card-body{
	padding: 0.5rem;
}

.card-inmueble-pro .col-btns{
	display: flex;
	align-items: center;
}


.card-inmueble-pro .btn-card,
.card-inmueble-pro-big .btn-card {
	min-width: 18%;
	padding: 0 10px 0 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 24px;
	margin: 0 5px 0 0;
	border-radius: 5px;
	font-size: 13px;
}

.card-inmueble-pro .btn-card  svg,
.card-inmueble-pro-big .btn-card  svg{
	width: 28px;
	height: 28px;
}

.card-inmueble-pro .btn-trash,
.card-inmueble-pro-big .btn-trash{
	width: 30px;
	height: 30px;
	padding: 0;
	position: relative;
	border: none;
}

.card-inmueble-pro .btn-trash svg,
.card-inmueble-pro-big .btn-trash svg{
	width: 30px;
	height: 30px;
}


.card-inmueble-pro .btn-edit,
.card-inmueble-pro-big .btn-edit{
	color: #2A8BF2;
	border: none;
	width: 30px;
	height: 30px;
	position: relative;
	top: 2px;
	color: lightblue 
	
}








.site-collapse-custom-collapse{
	background: white;
}

.site-collapse-custom-panel .ant-collapse-header {
	min-height: 50px !important;
}

.panelColapsed,
.panelNoColapsed{
	width:  10px;
	height: 10px;
	padding: 0px;
	margin: 0px;
	display: inline-block;
	position: absolute;
	top: 0px !important;
}
  
.panelNoColapsed svg{
	transform: rotate(-90deg);
}


.col-comunidad{
	padding: 10px;
} 

.row-comunidad{
	display: inline-block;
	width: 90%;
}

.col-img-comunidad{
	width: 100%;
	display: block;
	border-radius: 5px;
}

.imgCasa{
	width: 100%;
	border-radius: 5px;
	border:solid 1pzx #555;
	
}

.col-info-comunidad{
	padding-top: 15px;
}

.iconLogoCircle{
	float: right;
	display: block;
	position: relative;
	top:-16px;
	right: -10px;
	z-index: 1;
}

.text-cmn-nombre,
.text-cmn-comunidad,
.text-cmn-estado{
	font-style: normal !important;
	line-height: 12px !important;
	color: #000000;
	font-weight: bold !important;
	margin-bottom: 0px;
	padding-bottom: 0px;
}


.text-cmn-nombre .ant-typography,
.text-cmn-comunidad .ant-typography,
.text-cmn-estado .ant-typography,
.col-info-comunidad h5,
.col-info-comunidad .ant-col
{
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
}


.text-cmn-nombre{
	font-size: 1em !important;
}

.text-cmn-comunidad{
	font-weight: normal !important;
	font-size: 10px !important;
}

.text-cmn-estado{
	font-size: 10px !important;
}

.mr-20px{margin-right: 20px;}

.imgPerfilAgente{
	 
	 height: 80px;
	 width:  80px;
	
}