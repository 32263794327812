/*--------------CSS PARA EL MODAL DE INMUEBLE------------------*/
.col-imagen .card-image-big{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  position: relative;
  margin-bottom: 1rem;
}

.col-imagen .card-image-med{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
  position: relative;
  margin-bottom: 12px;
}

.col-imagen .card-image-big-blur{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  position: relative;
  margin-bottom: 1rem;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.col-imagen .image-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.col-imagen .left-circle{
	left: 5%;
}

.col-imagen .right-circle{
	right: 5%;
}

.col-imagen .left-circle,
.col-imagen .right-circle {
	position: absolute;
	top: 45%;
	z-index: 100;
	border: none;
	padding: 0;
	height: 35px;
	width: 35px;
	border-radius: 20px;
	text-align: center;
	background: rgba(0,0,0,0.5);
	color: white;
}

.col-imagen .image-content .text-white{
  margin: 0 0 1rem 0 !important;
}

.col-imagen .image-content .btn-yellow-image{
	border: none !important;
	background-color: #e0ef4d;
	color: black;
	height: 36px;
}

.col-inmueble{
	background-color: white;
	padding: 1rem 1.5rem;
}

.col-inmueble .col-btns-top{
	display: flex;
	justify-content: flex-end;
	align-items: center;

}

@media(max-width:  450px){

	.col-inmueble .btn-secondary{
		margin:0 2px;
	}

	.col-inmueble .btn-secondary svg{
		display: none;
	}

	.col-inmueble .col-btns-top{
		display: flex;
		justify-content: center;
	}
}

.col-inmueble .text-price{
	font-size: 26px;
	font-weight: 500;
}

.col-inmueble .text-price small{
	font-weight: 400;
}

.col-inmueble .text-svg *{
	fill: var(--bauens-blue);
}

.col-inmueble .text-svg svg{
	height: 40px;
	width: 50px;
}

.col-inmueble .text-svg{
	color: var(--bauens-blue);
	display: flex;
	align-items: center;
}

.col-inmueble .text-info{
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
	min-width: 100px;
}

@media(max-width: 576px){

	.col-inmueble .text-info{
		justify-content: flex-start;
	}
}


.col-inmueble .text-info.left{
	justify-content: flex-start !important;
	line-height: 30px;
	font-size: 16px;
}

.col-inmueble .text-info.left b{
	font-size: 20px !important;
	margin: 0 0.3rem;
	font-weight: 500;
}

.col-inmueble .text-direction{
	display: flex;
	margin: 0.5rem 0;
	font-size: 16px;
}

.col-inmueble .text-direction svg{
	margin-right: 0.5rem;
}

.text-info svg{
	margin-right: 0.5rem;
}

.col-inmueble .btn-black{
	margin-bottom:1rem;
	width: 100%;
	border-color: black;
	color: black;
	height: 36px;
}

.col-inmueble .btn-yellow{
	margin-bottom:1rem;
	width: 100%;
	border: none !important;
	background-color: #e0ef4d;
	color: black;
	height: 36px;
}

.img-mapa{
	max-width: 100%;
	max-height: 400px;
}

.col-inmueble .text-description{
	font-size: 12px;
}

.col-inmueble .text-strong{
	font-size: 20px;
	font-weight: 900;
}

.col-inmueble .text-strong-blue{
	font-size: 20px;
	color: var(--bauens-blue);
	font-weight: 100;
}

.col-inmueble .ant-checkbox-group{
	width: 100%;
	margin-left: 2rem;
	margin-top: 0.5rem;
}

.col-inmueble .text-rate{
	color: #1d7bff;
	font-weight: 700;
	font-size: 20px;
}

.car-list-element{
	padding-top: 5px;
}