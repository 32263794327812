

/*.ant-input,*/
/*.ant-input-affix-wrapper {*/
/*    width:100%!important;*/
/*    background-color: #E5EDF5!important;*/
/*    box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.07)!important;*/
/*}*/

/*.form-item-simple-input,*/
/*.ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow*/
/*{*/
/*    padding: 5px!important;*/
/*    height: 38px;*/

/*}*/

/*.form-item-select .ant-form-item-control-input-content{*/
/*    margin: -1px 0px 0 -3px!important*/
/*}*/


/*.form-item-select .ant-form-item-control-input {*/
/*    width: calc(100% + 10px)*/
/*}*/

/*.form-item-container-input.ant-input-affix-wrapper{*/
/*    height: 38px!important;*/
/*}*/
/*.ant-select-selector {*/
/*    padding: 3px 10px 0 10px!important;*/
/*    margin: -4px 0 0 -2px!important;*/
/*    height: 38px!important;*/
/*    background: #e5edf5!important;*/
/*    box-shadow: inset 5px 5px 10px 0px rgba(0,0,0,0.07)!important;;*/
/*}*/


.form-item-container{
    display: inline-block!important;
    width: 50% ;
    padding: 6px !important;
}
/* .ant-form-item {
     margin-bottom: 0px!important;
} */

.form-item-container-input{
    background: #e5edf5!important;
}

.form-item-container-input .ant-input{
    background: #e5edf5 !important;
}

.form-item-simple-input{
    background: #e5edf5 !important;
}

.select-simple .ant-select-selector{
    background: #e5edf5 !important;
}

/*.payform.responsive .form-item-container{*/
/*        display: inline-block!important;*/
/*        width: 100% !important;*/
/*        padding: 6px !important;*/
/*    }*/

.dynamic-delete-button{
    margin: 8px 8px!important;
    position: absolute;
    font-size: 20px;
}



#paypear-submit{
    display: block;
    margin: 3em auto 0 auto;
    background: #017AFF !important;
    width: 260px;
}


.ant-input.ant-input-lg{
    background: #e5edf5;
}


.w-100{
    width: 100%!important;
}
